import React, {useContext, useEffect, useState} from "react";
import Page from "../../common/ui/page";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useAuthContext } from "../../common/context/authContext";
import { usePagination } from "../../common/hooks/usePagination";
import { useSort } from "../../common/hooks/useSort";
import { useCsvExport, useDevicesHardwareCsvExport } from "../../common/hooks/useCsvExport";
import { useSearch } from "../../common/hooks/useSearch";
import { useSearchLight } from "../../common/hooks/useSearchLight";
import { QUERY_DEVICE_TYPE, QUERY_DEVICE_TYPES } from "../deviceData/deviceDataPage";
import { Link, Navigate, useMatch, useNavigate } from "react-router-dom";
import { useT } from "../../common/i18n";
import { useMultiSelection } from "../../common/hooks/useMultiSelection";
import { ButtonGroup } from "@salesforce/design-system-react";
import Button from "../../common/slds/buttons/button";
import { DeviceBulkOperationPage } from "./device/deviceBulkOperationPage";
import { useFilter } from "../../common/hooks/useFilter";
import { useParams } from "react-router";
import { Log } from "../../common/log";
import Roles from "../../model/roles";
import GenericAntDataTable from "../../common/ui/genericDataTable/genericAntDataTable";
import { useDebouncedPagination } from "../../common/hooks/useDebouncedPagination";
import { Layout } from "antd";
import Url from "../../common/url";
import { DeviceTypeMenu } from "./deviceTypeMenu";
import usePrevious from "../../common/hooks/usePrevious";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

const { Sider } = Layout;

function batteryCellValueLocalized(t) {
  const BATTERY_CELL_VALUE_TEMPLATE = `{{#if properties.[platform.powerStatus].value}}
{{#if (gte properties.[platform.powerStatus].value 7)}}
<div title="REPLACE_GOOD"><span class="slds-icon_container "><svg class="slds-icon slds-icon--x-small slds-icon-text-default" aria-hidden="true">
<?xml version="1.0" encoding="UTF-8"?><svg version="1.1" viewBox="0 0 6.8189 4.2441" xmlns="http:www.w3.org/2000/svg">
<g transform="matrix(0 -.46373 .45806 0 .37646 4.44)">
<g transform="matrix(.031559 0 0 .03462 -36.568 -16.557)">
<rect x="1182.1" y="480.22" width="270" height="394.29" ry="28.571" fill="none" stroke="#00a900" stroke-width="20"/>
<rect x="1280.4" y="460.91" width="79.543" height="10.057" ry="5.0286" fill="none" stroke="#00a900" stroke-width="12.8"/>
<g fill="#00a900">
<rect x="1210.7" y="767.36" width="212.86" height="71.429" ry="0"/>
<rect x="1210.7" y="683.17" width="212.86" height="71.429" ry="0"/>
<rect x="1210.7" y="598.98" width="212.86" height="71.429" ry="0"/>
<rect x="1210.7" y="514.79" width="212.86" height="71.429" ry="0"/></g></g></g></svg></svg></span></div>{{else}}{{#if (gte properties.[platform.powerStatus].value 4)}}<div title="REPLACE_LOW"><span class="slds-icon_container "><svg class="slds-icon slds-icon--x-small slds-icon-text-default" aria-hidden="true"><?xml version="1.0" encoding="UTF-8"?>
<svg version="1.1" viewBox="0 0 6.7733 4.2333" xmlns="http:www.w3.org/2000/svg"><rect transform="rotate(90)" x=".26422" y="-6.5091" width="3.7049" height="5.983" ry=".43355" fill="none" stroke="#fc0" stroke-width=".52843"/>
<rect transform="rotate(90)" x="1.6132" y="-.32171" width="1.0915" height=".15262" ry=".076306" fill="none" stroke="#fc0" stroke-width=".33819"/>
<rect transform="rotate(90)" x=".65355" y="-5.9104" width="2.9208" height="1.0839" ry="0" fill="#fc0" stroke-width=".40311"/>
<rect transform="rotate(90)" x=".65006" y="-4.4923" width="2.9208" height="1.0839" ry="0" fill="#fc0" stroke-width=".40311"/>
</svg></svg></span></div>{{else}}<div title="REPLACE_DRAINED"><span class="slds-icon_container "><svg class="slds-icon slds-icon--x-small slds-icon-text-default" aria-hidden="true">
<?xml version="1.0" encoding="UTF-8"?><svg version="1.1" viewBox="0 0 6.836 4.2181" xmlns="http:www.w3.org/2000/svg"><g transform="translate(.82805 -.83211)">
<g transform="matrix(0 -.014545 .015898 0 -9.6337 5.6986)"><rect x="54.571" y="579.61" width="270" height="394.29" ry="28.571" fill="none" stroke="#d40000" stroke-width="20"/>
<rect x="152.86" y="560.3" width="79.543" height="10.057" ry="5.0286" fill="#f00" stroke="#d40000" stroke-width="12.8"/>
<rect x="83.143" y="866.75" width="212.86" height="71.429" ry="0" fill="#d40000"/></g></g></svg></svg></span></div>{{/if}}{{/if}}{{else}}{{/if}}`;

  let value = BATTERY_CELL_VALUE_TEMPLATE.replace("REPLACE_GOOD", t("device.battery.good", "good"));
  value = value.replace("REPLACE_LOW", t("device.battery.low", "low"));
  return value.replace("REPLACE_DRAINED", t("device.battery.drained", "drained"));
}

const batteryWidth = "5rem";
const serialWidth = "8rem";

const formatTagPills = () => {
  return "{{#each tags}}<span class=\"slds-pill\"><span className=\"slds-pill__label\" title={{this}}>{{ this }}</span></span>{{/each}}";
};

const QUERY_DEVICES = gql`
    query ($orgId: ID, $devTypeId: ID, $page: PaginationInputType, $sort: SortInputType, $search: String, $filter: [FilterInputType!], $scope: String!) {
        devices(orgId: $orgId, sort: $sort, page: $page, search: $search, devTypeId: $devTypeId, filter: $filter, scope: $scope) {
            devices {
                createdAt
                id
                addr
                name
                description
                configRaw
                configuration{
                    name
                    value
                    setValue
                    updatedAt
                }
                propertiesRaw
                lastReceived
                serial
                tags
                initialConfigRaw
                firmwareVersion
                organisation {
                    id
                    name
                }
                deviceType {
                    id
                    displayName
                }
                app {
                    id
                    appId
                    name
                }
            }
            pagination {
                total
            }
        }
    }
`;

const DEFAULT_DEVICE_TABLE_CONFIG = (t) => {
  return {
    id: "defaultTableConfig_3e955e33-1898-4c14-ba0c-7f9bd77bfe8f",
    cols: [
      {
        id: "defaultCell_5c55e2be-2580-49ae-8e37-e1962fb2c532",
        heading: t("device.table-config.heading.serial", "Serial"),
        csvFormat: "{{serial}}",
        cell: {
          format: "{{serial}}"
        },
        sortProperty: "serial",
        filter: {
          type: "string",
          property: "serial",
          hasFilter: true
        },
        defaultVisible: true
      },
      {
        id: "defaultCell_4064e9b8-35b9-46cb-afbb-8073f62bf2aa",
        heading: t("device.table-config.heading.type", "Type"),
        csvFormat: "{{deviceType.displayName}}",
        cell: {
          format: "{{{deviceType.displayName}}}"
        },
        defaultVisible: true
      },
      {
        id: "defaultCell_93dca222-36aa-4e3c-b56f-8278a9ca28d0",
        heading: t("device.table-config.heading.app", "App"),
        csvFormat: "{{app.name}}",
        cell: {
          format: "{{app.name}}"
        },
        defaultVisible: false
      },
      {
        id: "defaultCell_0eeab618-487a-4382-8f65-b4cc217b800e",
        heading: t("device.table-config.heading.last-received", "Last Received"),
        sortProperty: "lastReceived",
        csvFormat: "{{date lastReceived}}",
        cell: {
          format: "{{date lastReceived}}"
        },
        defaultVisible: true
      },
      {
        id: "defaultCell_70d9fbae-1b54-48fb-9503-bdadc89c12f2",
        heading: t("device.table-config.heading.description", "Description"),
        csvFormat: "{{description}}",
        cell: {
          format: "{{description}}"
        },
        defaultVisible: true
      },
      {
        id: "defaultCell_5b6bf9f2-b69e-4a41-9cb8-cf20a2a9abe1",
        heading: t("device.table-config.heading.battery", "Battery"),
        cell: {
          format: batteryCellValueLocalized(t),
          isHtml: true
        },
        sortProperty: "properties.platform\".\"powerStatus.value",
        defaultVisible: true
      },
      {
        id: "defaultCell_8c6ac937-902b-4c56-aa01-4f56ab48aa34",
        heading: t("device.table-config.heading.tags", "Tags"),
        cell: {
          format: formatTagPills(),
          isHtml: true
        },
        filter: {
          type: "list",
          property: "tags",
          hasFilter: true
        },
        defaultVisible: true
      }
    ]
  };
};

const settingsKey = "lobaro.settings.dtm";

const DevicesPage = () => {
  const t = useT();
  const auth = useAuthContext();
  let { devTypeId } = useParams();
  const previousDevTypeId = usePrevious(devTypeId)

  const orgId = auth.organisationId();
  const previousOrgId = usePrevious(orgId)

  const noSelectMatch = useMatch("/organisation/devices/type");
  const [currentScope, setCurrentScope] = useState("organisation");
  const navigate = useNavigate();

  const localSettings = JSON.parse(localStorage.getItem(settingsKey));
  const initialCollapsed = localSettings?.collapsed !== null && localSettings?.collapsed !== undefined ? localSettings?.collapsed : false;
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const handleCollapse = (collapsed) => {
    saveSetting("collapsed", collapsed);
    setCollapsed(collapsed);
  };

  const license = useContext(FeatureContext)

  localStorage.setItem("lobaro.devices.devType", devTypeId);

  if (devTypeId === "all") {
    devTypeId = undefined;
  }

  // reset selection if devTypeId or orgId changes
  useEffect(() => {
    if (devTypeId !== previousDevTypeId || orgId !== previousOrgId) {
      multiSelection.setSelections([]);
    }
  }, [devTypeId, orgId]);

  useEffect(() => {
    const scope = localStorage.getItem("lobaro.devices.scope");
    if (scope) {
      setCurrentScope(scope);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("lobaro.devices.scope", currentScope);
  }, [currentScope]);

  const search = useSearch();
  const multiSelection = useMultiSelection();

  const devicesResult = useQuery(QUERY_DEVICES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      orgId: auth.organisationId(),
      search: search.getGraphqlSearchInput(),
      devTypeId: devTypeId,
      page: {
        offset: 0,
        limit: 100
      },
      sort: { field: "lastReceived", direction: "desc" },
      scope: currentScope
    }
  });
  //umbau alle hooks umdrehen
  const filters = useFilter(false, [], devicesResult.refetch);
  const sort = useSort({ property: "lastReceived", direction: "desc" }, devicesResult.refetch);
  const page = usePagination(100, devicesResult.refetch);
  const pageDebounced = useDebouncedPagination(100, devicesResult.refetch);
  const searchLight = useSearchLight(null, page, devicesResult.refetch);

  let deviceTypesVariables = { onlyWithExistingDevices: true };
  if (currentScope === "organisation") {
    deviceTypesVariables.orgId = orgId;
    deviceTypesVariables.withSubOrgs = false;
  } else if (currentScope === "organisationWithSub") {
    deviceTypesVariables.orgId = orgId;
    deviceTypesVariables.withSubOrgs = true;
  } else if (currentScope === "global") {
    deviceTypesVariables.orgId = null;
    deviceTypesVariables.withSubOrgs = false;
  }

  const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES, {
    variables: deviceTypesVariables
  });

  const devTypeResult = useQuery(QUERY_DEVICE_TYPE, {
    skip: !devTypeId,
    variables: {
      devTypeId: devTypeId
    }
  });

  const [showBulkOperationPage, setShowBulkOperationPage] = useState(false);

  const devType = devTypeResult.data?.deviceType;
  const deviceTypes = deviceTypesResult?.data?.deviceTypes;

  // In some cases a second query returns data as undefined.
  // I am not sure what causes this behaviour. Request answers seem to be correct.
  // It might have something to do with refetching the query while another query is still running.
  // This is a workaround fallback to the previous query result,
  // which is already present in all tested cases.

  let devicesData;
  if (devicesResult?.data) {
    devicesData = devicesResult.data;
  } else {
    devicesData = devicesResult?.previousData;
  }

  const devices = devicesData?.devices?.devices?.map((d) => {
    let properties;
    try {
      properties = JSON.parse(d.propertiesRaw) || {};
    } catch (err) {
      Log.Error("Failed to parse device propertiesRaw", err);
    }
    let initialConfig;
    try {
      initialConfig = JSON.parse(d.initialConfigRaw) || {};
    } catch (err) {
      Log.Error("Failed to parse device initialConfigRaw", err);
    }
    return {
      ...d,
      properties: properties,
      initialConfig: initialConfig
    };
  });

  useEffect(() => {
    if (devicesResult?.data?.devices?.pagination?.total !== undefined && devicesResult?.data?.devices?.pagination?.total !== null) {
      page.setTotal(devicesResult?.data?.devices?.pagination?.total);
      pageDebounced.setTotal(devicesResult?.data?.devices?.pagination?.total);
    }
  }, [devicesResult?.data]);

  const exportVars = {
    orgId: auth.organisationId(),
    sort: sort.getGraphqlSortInput(),
    devTypeId: devTypeId,
    scope: currentScope
  }

  const csvExport = useCsvExport(QUERY_DEVICES, {
      variables: exportVars,
  })
  const hardwareCSVExport = useDevicesHardwareCsvExport(exportVars)

  const saveSetting = (key, value) => {
    let settings = JSON.parse(localStorage.getItem(settingsKey));
    localStorage.setItem(
      settingsKey,
      JSON.stringify({
        ...settings,
        [key]: value
      })
    );
  };

  if (deviceTypes?.length >= 1 && noSelectMatch) {
    return <Navigate to={Url.join(noSelectMatch.pathname, "all")} />;
  }

  let tableConfig = devType?.deviceTableConfigRaw && JSON.parse(devType.deviceTableConfigRaw);

  if (!tableConfig || !tableConfig.cols || tableConfig.cols.length === 0) {
    tableConfig = DEFAULT_DEVICE_TABLE_CONFIG(t);
  }

  // add the serial column if it is not already present
  let serialCol = tableConfig?.cols?.find((col) => col?.cell?.format === "{{serial}}");
  if (tableConfig?.cols && !serialCol) {
    tableConfig.cols.unshift({
      id: "defaultCell_8baa14fa-daa3-4fd4-993e-11c54af76de1",
      heading: t("device.table-config.heading.serial", "Serial"),
      csvFormat: "{{serial}}",
      cell: {
        format: "{{serial}}"
      },
      sortProperty: "serial",
      filter: {
        type: "string",
        property: "serial",
        hasFilter: true
      },
      defaultVisible: true
    });
  }

  //set width of battery if no user preset is set
  let batteryColIndex = tableConfig?.cols?.findIndex((col) => {
    let heading = col?.heading?.toLowerCase();
    return (
      heading === "battery" ||
      heading === "batterie" ||
      heading === t("device.table-config.heading.battery", "Battery").toLowerCase() ||
      heading === "energy" ||
      heading === "energie"
    );
  });
  if (batteryColIndex !== -1 && tableConfig?.cols && !tableConfig?.cols[batteryColIndex]?.width) {
    tableConfig.cols[batteryColIndex].width = batteryWidth;
  }

  //set width of serial if no user preset is set
  let serialColIndex = tableConfig?.cols?.findIndex((col) => {
    let heading = col?.heading?.toLowerCase();
    return heading === "serial" || heading === "seriennummer" || heading === t("device.table-config.heading.serial", "Serial").toLowerCase();
  });
  if (serialColIndex !== -1 && tableConfig?.cols && !tableConfig?.cols[serialColIndex]?.width) {
    tableConfig.cols[serialColIndex].width = serialWidth;
  }

  if ((currentScope === "global" && !auth.hasRole(Roles.ADMIN)) || (currentScope === "organisationWithSub" && !auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN))) {
    return <Navigate to={"/organisation/devices"} replace={true} />;
  }

  const prefixCols = [
    {
      id: "defaultCell_ee352836-8de9-441b-a199-9f3b5a19bc19",
      heading: t("device.table-config.heading.name", "Name"),
      sortProperty: "name",
      csvFormat: "{{#if name}}{{name}}{{else}}- no name -{{/if}}",
      cell: {
        format: "{{#if name}}{{{name}}}{{else}}- no name -{{/if}}",
        href: "/#/organisation/devices/{{id}}/device-data"
      },
      filter: {
        type: "string",
        property: "name",
        hasFilter: true
      },
      defaultVisible: true
    },
    {
      id: "defaultCell_6e3943df-b580-4275-b9e0-fcc77f2db3ec",
      heading: t("device.table-config.heading.address", "Address"),
      sortProperty: "addr",
      csvFormat: "{{addr}}",
      cell: {
        format: "{{addr}}",
        href: "/#/organisation/devices/{{id}}/device-data"
      },
      filter: {
        type: "string",
        property: "addr",
        hasFilter: true
      },
      defaultVisible: true
    },
    {
      id: "defaultCell_379989ea-094c-44bb-867d-6b6929f744f6",
      heading: t("device.table-config.heading.createdAt", "Created At"),
      sortProperty: "createdAt",
      csvFormat: "{{date createdAt}}",
      cell: {
        format: "{{date createdAt}}"
      },
      defaultVisible: false
    }
  ];

  if (currentScope !== "organisation") {
    prefixCols.push({
      id: "defaultCell_edd2ce9a-bc1d-492a-8794-b9ae8c2d6d3d",
      heading: t("device.table-config.heading.organisation", "Organisation"),
      csvFormat: "{{organisation.name}}",
      cell: {
        format: "{{organisation.name}}"
      },
      defaultVisible: true
    });
  }

  if (!license.validateFeatures("lobaro-device-gateway")) {
    return <Layout
      style={{
        minHeight: "100vh"
      }}>
      <Sider width={"15rem"} theme={"light"} collapsed={collapsed}>
        <DeviceTypeMenu
          deviceTypes={[]}
          baseUrl="/organisation/devices/type"
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          currentScope={currentScope}
          setCurrentScope={setCurrentScope}
          showDeviceTypeAll={false}
        />
      </Sider>
      <Layout>
        <Page
          trail={[
            <Link to="/organisation/devices" key={1}>
              {t("devices.page.title", "Devices")}
            </Link>
          ]}
          title={t("devices.page.title", "Devices")}
        >
          <FeatureNotEnabled />
        </Page>
      </Layout>
    </Layout>
  }

  if (showBulkOperationPage) {
    return <DeviceBulkOperationPage setShowBulkOperationPage={(b) => setShowBulkOperationPage(b)} page={page}
                                    multiSelection={multiSelection} />;
  }

  let info = [];
  if (devTypeId) {
    let dt = deviceTypes?.find((dt) => dt.id === devTypeId);
    info.push(
      <Link to={"/deviceTypes/" + dt?.id} key={1}>
        {dt?.displayName}
      </Link>
    );
  }

  if (devTypeId && deviceTypes && !deviceTypes?.find((dt) => dt.id === devTypeId)) {
    return <Navigate replace={true} to={"/organisation/devices/type/all"} />;
  }

  return (
    <Layout
      style={{
        minHeight: "100vh"
      }}>
      <Sider width={"15rem"} theme={"light"} collapsed={collapsed}>
        <DeviceTypeMenu
          deviceTypes={deviceTypes}
          baseUrl="/organisation/devices/type"
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          currentScope={currentScope}
          setCurrentScope={setCurrentScope}
          showDeviceTypeAll={true}
        />
      </Sider>
      <Layout>
        <Page
          trail={[
            <Link to="/organisation/devices" key={1}>
              {t("devices.page.title", "Devices")}
            </Link>
          ]}
          title={t("devices.page.title", "Devices")}
          info={info}
          actions={
            <ButtonGroup>
              <Button iconName={"edit"} onClick={() => setShowBulkOperationPage(true)}
                      disabled={!multiSelection?.selections?.length}>
                Bulk Operations
              </Button>
              {auth.hasRole("admin") ? (
                <Button
                  iconName={"upload"}
                  onClick={() => {
                    if (devTypeResult.data?.deviceType?.id) {
                      navigate(`/organisation/devices/import/${devTypeResult.data?.deviceType?.id}`);
                    } else {
                      navigate(`/organisation/devices/import`);
                    }
                  }}>
                  Import
                </Button>
              ) : (
                <div />
              )}
            </ButtonGroup>
          }>
            <GenericAntDataTable
              items={devices}
              id="DevicesTable"
              selection={multiSelection}
              selectRows={true}
              gqlResult={devicesResult}
              useGqlLoadingSpinner={false}
              csvExport={csvExport}
              hardwareCsvExport={hardwareCSVExport}
              page={pageDebounced}
              sort={sort}
              search={searchLight}
              filters={filters}
              fixedLayout={true}
              tableConfigDefault={tableConfig}
              prefixCols={prefixCols}
            />
        </Page>
      </Layout>
    </Layout>
  );
};

export default DevicesPage;
