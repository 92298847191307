import {useT} from "../../common/i18n";
import {useAuthContext} from "../../common/context/authContext";
import {Link, Outlet} from "react-router-dom";
import Page from "../../common/ui/page";
import {Tab, TabNavigation} from "../../common/ui/tabNavigation";
import React from "react";
import {OrgVerticalNav, QUERY_ORGANISATION} from "../config/orgConfigPage";
import {useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../common/graphql";

export const OrgApiStatisticsPage = () => {
    const t = useT();
    const auth = useAuthContext();

    let organisationResult = useQuery(QUERY_ORGANISATION, {
        variables: {
            orgId: auth.organisationId(),
        },
    })

    const loading = useGraphqlLoadingComponent(organisationResult);
    if (loading) {
        return loading;
    }

    const org = organisationResult?.data?.organisation;

    const hasSubOrgs = org.subOrganisations.length > 0;

    // Route will always route to stats of user organisation via auth context
    // Don't show tabs if user is not org admin TODO: Better (looking) error message
    if (!(auth.hasRole("org-admin"))) {
        return (
            <Page
                trail={[<Link key={1} to={"."}>{t("config.settings.api-statistics.title", "API Statistics")}</Link>]}
                title={t("config.settings.api-statistics.title", "API Statistics")}
            >
                <div>Insufficient rights!</div>
            </Page>
        )
    }

    return (
        <div className={"slds-grid"}>
            <OrgVerticalNav/>
            <div className={"slds-col"}>
                <Page
                    trail={[<Link key={1}
                                  to={"."}>{t("config.settings.api-statistics.title", "API Statistics")}</Link>]}
                    title={t("config.settings.api-statistics.title", "API Statistics")}
                >
                    <TabNavigation tabs={[
                        <Tab key={"endpoint"}
                             to={"endpoint"}>{t("config.settings.api-statistics.endpoint", "Endpoint")}</Tab>,
                        /* TODO: Hide if rest-api feature isn't available in platform? */
                        hasSubOrgs && auth.hasRole("org-admin") ?
                            <Tab key={"organisation"} to={"suborgs"}>{t("common.organisation", "Organisation")}</Tab> : null,
                    ]}>
                        <Outlet context={hasSubOrgs}/>
                    </TabNavigation>
                </Page>
            </div>
        </div>
    )
}