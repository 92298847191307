import React from "react";
import Spinner from '../../common/slds/spinner/spinner';
import Box from '../../common/ui/box';
import * as log from "../../common/log";
import {Log} from "../../common/log";
import _ from "underscore";
import Card, {CardBody, CardHeader, CardHeaderTitle} from "../../common/slds/cards/card";
import Tile from "../../common/slds/tiles/tile";
import Media, {MediaBody, MediaFigure} from "../../common/slds/mediaObjects/media";
import {Icon} from "../../common/slds/icons/icon";
import Tooltip from "../../common/slds/tooltip/tooltip";
import DataTable, {Col, Row, TableBody, TableHead} from "../../common/slds/dataTable";
import {useT} from "../../common/i18n";
import ErrorBoundary from "../../common/ui/errorBoundary";
import PropTypes from "prop-types";
import moment from "moment";


const WmbusEncryptionInfoIcon = (props) => {
    const t = useT();
    const wmbus = props.wmbus
    const header = wmbus?.Header || {};
    let encryptedTelegram = !!header?.EncryptionMode;
    let isEncrypted = wmbus.Body?.IsEncrypted;
    let statusText = `${header.EncryptionModeString}`;
    if (encryptedTelegram) {
        statusText = t("wmbus-telegram.encrypted", 'Encrypted') + ` ${statusText} - ${header.EncryptedBlocks} (${header.EncryptedBlocks * 32} bytes)`;
    }

    let iconName = "preview";
    if (isEncrypted) {
        iconName = "lock";
    } else if (encryptedTelegram) {
        iconName = "unlock";
    }

    return <Tooltip position="absolute" top={"-45px"} left={"-17px"}
                              content={<div className="slds-nowrap">{statusText}</div>}>
        <Icon name={iconName} size={"xx-small"}/>
    </Tooltip>;
}

const WmbusHeaderTable = (props) => {
    const t = useT();
    const wmbusHeader = [];
    const wmbus = props.mbus || {};
    const header = wmbus?.Header || {};

    if (!_.isEmpty(wmbus)) {
        if (header) {
            wmbusHeader.push(
                /*{
                     name: "EncryptedBlocks",
                     value: `${header.EncryptedBlocks} (${header.EncryptedBlocks * 32} bytes)`,
                 },
                 {
                     name: "HopCount",
                     value: `${header.HopCount}`,
                 },
                 {
                     name: "IsAccessible",
                     value: `${header.IsAccessible ? "true" : "false"}`,
                 },
                 {
                     name: "IsBidirectionalMode",
                     value: `${header.IsBidirectionalMode ? "true" : "false"}`,
                 },
                 {
                     name: "IsSynchronous",
                     value: `${header.IsSynchronous ? "true" : "false"}`,
                 },
                 {
                     name: "ReservedBit",
                     value: `${header.ReservedBit ? "true" : "false"}`,
                 },
                 {
                     name: "TelegramType",
                     value: `${header.TelegramType}`,
                 },*/
            );
        }
    }


    // Because react ...
    wmbusHeader.forEach((h, i) => {
        h.id = `${i}`;
    });

    const longHeaderCis = [
        '0x6b','0x6f','0x72','0x73','0x75','0x7c','0x7e',   /* CI Fields for receiving wMBus with long headers at platform, in hex */
        '0x53','0x5b','0x60','0x64','0x6c','0x6d'           /* CI Fields for transmitting wMBus with long headers from platform, in hex */
    ]
    let isLongHeader = longHeaderCis.indexOf(wmbus?.CiField) >= 0

    return <>
        <Card bordered={true}>
            <CardHeader>
                <CardHeaderTitle>
                    ID:&nbsp;<span style={{fontWeight: "bold"}}> {wmbus.IdString}</span>&nbsp;<span className="slds-text-body--regular">(v{wmbus.Version})</span>
                </CardHeaderTitle>
            </CardHeader>
            <CardBody inner={true}>
                <Tile>
                    <Media>
                        <MediaFigure>
                            <Icon category={"standard"} name={isLongHeader ? "work_step_template" : "work_step"}/>
                        </MediaFigure>
                        <MediaBody>
                            {!_.isEmpty(wmbus) && <>
                                <div><span className="slds-text-title_bold">{wmbus.MFieldCodeString}</span> - {wmbus.MFieldLongString}</div>
                                <div className="slds-grid">
                                    <div className="slds-col slds-no-flex slds-m-right--medium">
                                        <div><span className="slds-text-title_bold">{wmbus.DeviceString}</span> ({wmbus.Device})</div>
                                    </div>
                                </div>
                                <div><span className="slds-text-title_bold">Layer:</span> Link Layer</div>
                                <ul className="slds-list_horizontal slds-has-dividers_left slds-m-top--x-small">
                                    <li className="slds-item">C-Field: {wmbus.CFieldString}</li>
                                    <li className="slds-item">CI-Field: {`${wmbus.CiField} (${wmbus.HeaderKnown ? 
                                        (wmbus.PayloadKnown ? t("wmbus-telegram.parsable", "Parsable") : t("wmbus-telegram.payload-unknown", "Payload Unknown") ) 
                                        : t("wmbus-telegram.header-unknown", "Header Unknown")})`}</li>
                                    {header.AccessNumber !== undefined ? <li className="slds-item">AccessNumber: {header.AccessNumber}</li> : null}
                                    {wmbus.IsCompactFrame ? <li className="slds-item">{t("wmbus-telegram.compact-frame", "Compact frame")}</li> : null}
                                </ul>
                            </>}
                        </MediaBody>
                    </Media>


                </Tile>
            </CardBody>
        </Card>

        {(isLongHeader === true) && <Card bordered={true}>
            <CardHeader>
                <CardHeaderTitle>
                    ID:&nbsp;<span style={{fontWeight: "bold"}}>{header.IdString}</span>&nbsp;<span className="slds-text-body--regular">(v{header.Version})</span>
                </CardHeaderTitle>
            </CardHeader>
            <CardBody inner={true}>
                <Tile>
                    <Media>
                        <MediaFigure>
                            <Icon category={"standard"} name={"work_step"}/>
                        </MediaFigure>
                        <MediaBody>
                            <>
                                <div><span className="slds-text-title_bold">{header.MFieldCodeString}</span> - {header.MFieldLongString}</div>
                                <div className="slds-grid">
                                    <div className="slds-col slds-no-flex slds-m-right--medium">
                                        <div><span className="slds-text-title_bold">{header.DeviceString}</span> ({header.DeviceType.toString(16).padStart(2,'0')})</div>
                                        <div><span className="slds-text-title_bold">Layer:</span> Application Layer</div>
                                    </div>
                                </div>
                            </>

                        </MediaBody>
                    </Media>


                </Tile>
            </CardBody>
        </Card>}
    </>;
};

const WmbusDataRacordsTable = (props) => {
    const wmbusFields = [];
    const t = useT();
    let {mbus, parseError} = props;

    if (!mbus && parseError) {
        return <Box className="slds-m-top--small"><p>{t("wmbus-telegram.parse-error", "Parse Error: {{parseError}}", {parseError: parseError})}</p></Box>;
    }

    if (!mbus) {
        return <Spinner type="inlined-container"/>;
    }

    let body = mbus.Body;

    if (!body) {
        return <div>No Data</div>;
    }

    //Log.Debug("props", props);
    log.Debug("mbus", mbus);
    //Log.Debug("body", body);


    if (body.DataRecords && body.DataRecords.length > 0) {
        body.DataRecords.forEach((d, i) => {
            wmbusFields.push({
                id: `${i}`,
                ...d,
            });
        });
    }

    let noDataMsg = null;
    if (body.IsEncrypted) {
        noDataMsg = t("wmbus-telegram.payload-encrypted", "Payload encrypted");
    } else if (!mbus.PayloadKnown) {
        let ciNum = parseInt(mbus.CiField, 16);
        if (ciNum >= 0xA0 && ciNum <= 0xB7) {
            noDataMsg = t("wmbus-telegram.payload-format-manufacturer-specific", "Payload format unknown (CiField A1 - B7 are manufacturer specific)");
        } else {
            noDataMsg = t("wmbus-telegram.payload-format-unknown", "Payload format unknown");
        }
    }

    return <div>
        <DataTable fixedLayout={false} items={wmbusFields}>
            <TableHead>
                <Col header>{t("wmbus-telegram.description", "Description")}</Col>
                <Col header>{t("wmbus-telegram.value", "Value")}</Col>
                <Col header>{t("wmbus-telegram.storrage-no", "Storage No.")}</Col>
                <Col header>{t("wmbus-telegram.tariff", "Tariff")}</Col>
                <Col header width={"100%"}>{t("wmbus-telegram.Subunit", "Subunit")}</Col>
                {/* <HeaderCol>Format</HeaderCol>*/}
            </TableHead>
            <TableBody>
                {wmbusFields.map((it, i) => {
                    let unit = it.VifUnit;
                    // Not needed with latest wmbus parser
                    if (unit === "-") {
                        unit = "";
                    }
                    unit = unit.replace("^3", "³");
                    unit = unit.replace("^2", "²");


                    let func = it.DifFunctionString;
                    if (func === "Current Value") {
                        func = "";
                    }

                    let value = it.ValueString;
                    const description = it.VifQuantity;

                    // seconds
                    if (unit === "s") {
                        value = moment.duration(it.ValueScaled, 'seconds').humanize(false);
                        unit = "";
                    }

                    // Time point (date) is old and was changed to Date
                    if (description === "Time point (date)" || description === "Date") {
                        value = moment(value).format("DD.MM.YYYY");
                    }
                    if (description === "Point in Time") {
                        value = moment.unix(it.Value).format("DD.MM.YYYY HH:mm:ss");
                    }

                    // Time point (date & time) is old and was changed to Date
                    if (description === "Time point (date & time)" || description === "Time & Date") {
                        value = moment(value).format("DD.MM.YYYY HH:mm:ss");
                    }

                    let compactProfileSubTable = null
                    //if CompactProfile display SubTable Instead of ValueString
                    if (it.CompactProfile) {
                        compactProfileSubTable = <WmbusCompactProfileSubTable dataRecord={it} unit={unit} description={description}   />
                    }

                    return <Row key={i}>
                        {compactProfileSubTable ?
                            <Col className={"slds-align-top slds"} noStripes>{"CompactFrame"}</Col> :
                            <Col noStripes>{description} {func && `(${func})`}</Col>
                        }
                        {compactProfileSubTable ?
                            <Col noStripes> <div> <ErrorBoundary> {compactProfileSubTable} </ErrorBoundary> </div></Col> :
                            <Col noStripes>{value} {unit}{it.VifEDescription ? ` (${it.VifEDescription})` : ""}</Col>
                        }
                        { compactProfileSubTable ?
                            <Col className={"slds-align-top slds"} noStripes>{it.StorageNo || "0"}</Col> :
                            <Col noStripes>{it.StorageNo || "0"}</Col>}
                        { compactProfileSubTable ?
                            <Col className={"slds-align-top slds"} noStripes>{(it.Tariff || "0")}</Col> :
                            <Col noStripes>{(it.Tariff || "0")}</Col>}
                        { compactProfileSubTable ?
                            <Col className={"slds-align-top slds"}  noStripes>{(it.DifSubunit || "0")}</Col> :
                            <Col noStripes>{(it.DifSubunit || "0")}</Col>
                        }
                    </Row>;
                })}
            </TableBody>
        </DataTable>
        {noDataMsg ?
            <Box className="slds-m-top--small"><p>{noDataMsg}</p></Box> : null}
        {noDataMsg && !body.IsEncrypted ?
            <div className={"slds-m-top--small slds-box"}>
            {t("wmbus-telegram.decrepted-raw-body","Decrypted Raw Body")}<br/>
            <textarea  className="slds-textarea slds-text-font_monospace" readOnly rows={2} defaultValue={body.Raw}/>
            </div>
            : null}
        {parseError ?
            <Box className="slds-m-top--small"><p>{t("wmbus-telegram.parse-error", "Parse Error: {{parseError}}", {parseError: parseError})}</p></Box> : null}
    </div>;
}

WmbusDataRacordsTable.propTypes = {
    mbus: PropTypes.object,
    parseError: PropTypes.string,
};

function WmbusTelegramDetails(props) {
    const t = useT();
    let {parseError, mbusJson} = props;
    const mbus = mbusJson;

    if (!parseError) {
        // The parse error could be inside the mbus data
        parseError = mbus.parseError;
        if (!parseError) {
            parseError = mbus.BodyParseError;
        }
    }

    Log.Debug("WmbusTelegramDetails.mbus", mbus);

    if (_.isEmpty(mbus)) {
        if (parseError) {
            return <Box iconType={"warning"} className="slds-m-top--small"><p>{t("wmbus-telegram.parse-error", "Parse Error: {{parseError}}", {parseError: parseError})}</p></Box>
        }
        return <Box className="slds-col slds-size--1-of-1 slds-m-top--small"><p>{t("wmbus-telegram.no-wmbus-data", "No wMbus data")}</p></Box>;
    }

    return <div className="slds-grid slds-gutters_direct slds-wrap">
        <div className="slds-col slds-size--1-of-1 slds-p-bottom--x-small">
            <ErrorBoundary>
                <WmbusHeaderTable mbus={mbus}/>
            </ErrorBoundary>
        </div>

        <div className="slds-col slds-size--1-of-1">
            <ErrorBoundary>
                <Card bordered={true}>
                    <CardHeader>
                        <CardHeaderTitle>{t("wmbus-telegram.wmbus-data", "wMbus Data")}</CardHeaderTitle>&nbsp;
                        <WmbusEncryptionInfoIcon wmbus={mbus} />
                    </CardHeader>
                    <WmbusDataRacordsTable mbus={mbus} parseError={parseError}/>
                </Card>
            </ErrorBoundary>
        </div>
    </div>;
}

WmbusTelegramDetails.propTypes = {
    onClose: PropTypes.func,
    // String or object
    mbusJson: PropTypes.any,
    parseError: PropTypes.string,
};

export default WmbusTelegramDetails;

function WmbusCompactProfileSubTable(props) {
    let dataRecord = props.dataRecord
    let compactProfile = dataRecord.CompactProfile
    let unit = props.unit
    let description = props.description
    let t = useT()

    return <DataTable bordered={false} fixedLayout={false} items={compactProfile.History}>
        <TableHead>
            <Col header noStripes={true}>{t("wmbus-telegram.compact-profile.time", "Time")}</Col>
            <Col header noStripes={true}>{t("wmbus-telegram.description", "Description")}</Col>
            <Col header noStripes={true}>{t("wmbus-telegram.compact-profile.value", "Value")}</Col>
          </TableHead>
        <TableBody>
            {compactProfile.History?.map((it, i) => {
                return <Row key={i}>
                    <Col noStripes>{moment(it.Time).format("DD.MM.YYYY HH:mm:ss")} </Col>
                    <Col noStripes>{description} </Col>
                    <Col noStripes>{prettyPrintCompactProfileValue(it.ValueString || it.Value, dataRecord.VifExponent)} {unit}{it.VifEDescription ? ` (${it.VifEDescription})` : ""}</Col>
                </Row>;
            })}
        </TableBody>
    </DataTable>
}

function prettyPrintCompactProfileValue(value, exponent) {
    if (exponent === null || exponent === undefined || value === null || value === undefined) {
        return value
    }
    if (value === "NaN" || value === "+Inf" || value === "-Inf" || value === "Inf") {
        return value
    }

    let decimalPlaces = getDecimalPlacesFromExponent(exponent)
    if (decimalPlaces === 0) {
        return value
    }
    switch (typeof value) {
        case 'number':
            var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimalPlaces || -1) + '})?');
            return value.toString().match(re)[0];
        default:
            return value
    }
}

function getDecimalPlacesFromExponent(exponent) {
    if (exponent <= 0){
        return 0
    }
    let digits = Math.log10(1 / exponent)
    if (digits < 0){
        digits = 0
    }
    return digits




}