import * as React from "react";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import gql from "graphql-tag";
import Json from "../../../common/ui/json";
import {useQuery} from "@apollo/client";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {usePagination} from "../../../common/hooks/usePagination";
import {useSort} from "../../../common/hooks/useSort";
import {useT} from "../../../common/i18n";
import {useParams} from "react-router";
import { useFilter } from "../../../common/hooks/useFilter";
import { ninetyDays } from "../../../common/magicNumbers";
import {useContext} from "react";
import {FeatureContext} from "../../../common/context/featureContext";
import {FeatureNotEnabled} from "../../../common/featureNotEnabled";

const QUERY_SENSOR_DATA = gql`
    query device($devId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        sensorData(devId: $devId, page: $page, filter: $filter, sort: $sort) {
            id
            receivedAt
            createdAt
            dataRaw
            type
            parseError
            datasourceString
        }
        device(id: $devId) {
            addr
            id
            name
            description
            configRaw
            deviceType {
                id
                name
            }
            app {
                id
                configRaw
            }
        }
    }
`;

export default function DeviceSensorData() {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled/>
    }
    let initialFilter = [{"field": 'receivedAt', "op": "lte", "value": new Date(Date.now())},
        {"field": 'receivedAt', "op": "gte", "value": new Date(Date.now()-ninetyDays)}]

    const devId = useParams().deviceId;
    const t = useT();


    const sort = useSort();
    const page = usePagination(100);
    const sensorDataResult = useQuery(QUERY_SENSOR_DATA, {
        fetchPolicy: "cache-and-network",
        pollInterval: 5000,
        variables: {
            devId: devId,
            page: page.getGraphqlPageInput(),
            sort: sort.getGraphqlSortInput()
        }
    });

    const filters = useFilter(true, initialFilter, sensorDataResult.refetch)

    const loading = useGraphqlLoadingComponent(sensorDataResult);
    if (loading) {
        return loading;
    }
    page.setPageItemCount(sensorDataResult.data.sensorData.length);
    
    let data = sensorDataResult.data;
    const defaultTableConfig = {
        cols: [
            {
                heading: t("device.sensor-data.table.received", "Received"),
                width: "10rem",
                cell: {
                    format: "{{date receivedAt 'DD.MM.YYYY HH:mm:ss'}}",
                },
            },
            {
                heading: t("device.sensor-data.table.type", "Type"),
                width: "8rem",
                cell: {
                    format: "{{type}}",
                },
                filter: {
                    type: "string",
                    property: "data_type",
                    hasFilter: true,
                },
            },
            {
                heading: t("device.sensor-data.table.datasource", "Datasource"),
                width: "8rem",
                cell: {
                    format: "{{datasourceString}}",
                },
            },
            {
                heading: t("device.sensor-data.table.parser-result", "Parser Result"),
                cell: {
                    format: "{{parseError}}",
                },
            },
        ],
    }

    return <GenericDataTable
        id={"sensor-data-table"}
        tableConfigName={`device-sensor-data-${data.device.deviceType.name}`}
        tableConfigDefault={defaultTableConfig}
        items={sensorDataResult.data.sensorData.map((d) => {
            return {
                ...d,
                data: JSON.parse(d.dataRaw)
            };
        })}
        //selectedItem={data.parsedData[0]}
        renderDetails={(item) => {
            return <Json json={item}/>
        }}
        gqlResult={sensorDataResult}
        page={page}
        sort={sort}
        filters={filters}
    />
}