import React, {useState} from "react"
import {useT} from "../../common/i18n";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {QUERY_API_STATISTICS} from "./queries";
import {useQuery} from "@apollo/client";
import {useFilter} from "../../common/hooks/useFilter";
import {ApiStatisticGQL, ApiStatisticResult, EndpointStatViewProps} from "./apiStatisticsPage";
import FilterPanel from "../../common/ui/filterPanel";
import {Col, Row} from "antd";
import {Checkbox} from "../../common/slds/inputs/Checkbox";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {useOutletContext} from "react-router";

const initialFilter = [{"field": 'createdAt', "op": "gte", "value": new Date(Date.now()-1000*60*60*24)}, {"field": 'createdAt', "op": "lte", "value": new Date(Date.now())}] // 24 hours

export const EndpointStatView = ({forOrg}: EndpointStatViewProps) => {
    const orgId: number = forOrg || 0
    const t = useT()
    const filters = useFilter(true, initialFilter);
    const sort = {field: "createdAt", direction: "ASC"} // static sort for Highcharts
    const [withSuborgs, setSuborgs] = useState<boolean>(false)
    const hasSuborgs = useOutletContext()

    const apiData = useQuery<ApiStatisticGQL>(QUERY_API_STATISTICS, {
        variables: {
            filter: filters.getGraphqlFilterInput(),
            sort: sort,
            separateOrgs: false,
            orgID: orgId,
            withSuborgs: withSuborgs,
        },
    });
    const loading = useGraphqlLoadingComponent(apiData)

    const resUrls = new Set(apiData.data?.apiStatistic.map((stat: ApiStatisticResult) => stat.url) || null)

    const chartOptions: Highcharts.Options = {
        title: {
            text: t("config.settings.api-statistics.endpoint-chart", "API access by endpoint") || "API access by endpoint"
        },
        xAxis: {
            gridLineWidth: 1,
            type: "datetime",
            title: {
                text: t("config.settings.api-statistics.time", "Time") || "Time"
            },
        },
        yAxis: {
            title: {
                text: t("config.settings.api-statistics.count", "Access count") || "Access count"
            },
        },
        series: [...resUrls.values()].map((url) => {

            return {
                type: "column",
                name: url,
                data: (apiData.data?.apiStatistic.filter(stat => stat.url == url).map((stat: ApiStatisticResult) => {
                    //Log.Debug(stat)
                    //Log.Debug([Date.parse(stat.createdAt), stat.count])
                    return [Date.parse(stat.createdAt), stat.count]
                }) || []),
            }
        }),
        chart: {
            panKey: "ctrl",
            zooming: {
                type: "x",
            }
        },
    }

    return (
        <div className="slds-m-horizontal--x-small">
            <FilterPanel align={"center"} filters={filters} size={"full"} fieldName={"createdAt"}/>
            {hasSuborgs && (orgId > 0) ? <Checkbox onClick={e => setSuborgs(e.target.checked)} checked={withSuborgs} label={t("config.settings.api-statistics.include-suborgs", "Include sub-organisations") || "Include sub-organisations"}/> : null}
            {loading || apiData.data?.apiStatistic.length ? <HighchartsReact highcharts={Highcharts} options={chartOptions}/> : <Row align={"middle"} justify={"center"} className={"slds-p-vertical--xx-large"}><Col>{t("config.settings.api-statistics.no-results", "No statistics found")}</Col></Row> }
        </div>
    )
}
