/* eslint-disable graphql/template-strings */
import { Link } from "react-router-dom"
import { useAuthContext } from "../../../common/context/authContext"
import { useMutation, useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { useNotificationContext } from "../../../notifications/notificationContext"
import Page from "../../../common/ui/page"
import { Formik } from "formik"
import { Form } from "../../../common/ui/form/formik"
import {
    CancelButtonField,
    FormActions, SldsCheckboxField,
    SldsFileSelectorField,
    SldsFormElement,
    SldsFormElementCompound,
    SldsFormElementLabel,
    SldsFormElementRow,
    SldsInputField,
    SubmitButtonField,
} from "../../../common/ui/form/formElements"
import React from "react"
import { backendUrl } from "../../../common/helper"
import Thumb from "../../../common/slds/fileSelector/thumb"
import { useGraphqlLoadingComponent } from "../../../common/graphql"
import { useT } from "../../../common/i18n"
import roles from "../../../model/roles"

const MUTATION_UPDATE_ORGANISATION = gql`
    mutation($orgId: ID!, $organisation: OrganisationInput!){
        updateOrganisation(id: $orgId, input: $organisation) {
            id
            name
            logoUrl
            dataRetentionDays
            allowWmbusKeysInPlainText
        }}
`
const QUERY_ORGANISATION = gql`
    query($orgId: ID!){
        organisation: getOrganisation(id: $orgId) {
            id
            name
            logoUrl
            dataRetentionDays
            allowWmbusKeysInPlainText
        }}
`

export default function OrganisationSettingsPage() {
    const t = useT()
    const auth = useAuthContext()

    let organisationResult = useQuery(QUERY_ORGANISATION, {
        variables: {
            orgId: auth.organisationId(),
        },
    })

    let [updateOrganisation] = useMutation(MUTATION_UPDATE_ORGANISATION, {
        variables: {
            orgId: auth.organisationId(),
        },
    })

    const org = organisationResult?.data?.organisation
    const notify = useNotificationContext()
    const logoUrl = org?.logoUrl ? `${backendUrl()}/${org?.logoUrl}` : null

    const loading = useGraphqlLoadingComponent(organisationResult, {})
    if (loading) {
        return loading
    }


    return <Page
        trail={[<Link to={window.location.href} key={1}>{t("org.config.nav.settings", "Settings")}</Link>]}
        title={t("org.config.settings.title", "Settings")}
    >
        <div className="slds-p-around--small">

            <div
                className="slds-text-heading--medium slds-m-bottom--x-small">{t("org.config.settings.heading", "Organisation")}</div>
            <Formik
                onSubmit={(values) => {
                    updateOrganisation({
                        variables: {
                            organisation: {
                                name: values.name,
                                logo: values.logo,
                                dataRetentionDays: values.dataRetentionDays,
                                allowWmbusKeysInPlainText: values.allowWmbusKeysInPlainText,
                            },
                        },
                    }).then(() => {
                        notify.info(t("org.config.settings.notify.updated-org", "Updated organisation"))
                    }).catch((e) => {
                        notify.error(t("org.config.settings.notify.update-org-failed", "Failed to update organisation"), e)
                    })
                }}
                initialValues={{
                    ...org, logo: null, logoUrl: logoUrl,
                }}
                initialStatus={{
                    readOnly: true, canEdit: true,
                }}
                enableReinitialize={true}
            >
                {formik => {
                    return <Form>
                        <SldsInputField name={"name"} />
                        <SldsFormElementCompound label={t("org.config.settings.logo", "Logo")}>
                            <SldsFormElementRow>
                                <SldsFormElement>
                                    <SldsFormElementLabel>{t("org.config.settings.current-logo", "Current Logo")}</SldsFormElementLabel>
                                    <Thumb file={formik.values.logoUrl} height={"40px"} />
                                </SldsFormElement>
                                <SldsFileSelectorField name={"logo"}
                                                       label={t("org.config.settings.update-logo", "Update Logo")}
                                                       buttonLabel={t("file-selector.button.upload-file", "Upload Files")}
                                                       thumbHeight={"40px"} withThumb={true} />
                            </SldsFormElementRow>
                            <SldsInputField name={"dataRetentionDays"}
                                            type={"number"}
                                            label={t("org.config.settings.data-retention.days", "Data Retention Days")}
                                            canEdit={auth.hasRole(roles.ADMIN)} />
                            <SldsCheckboxField name={"allowWmbusKeysInPlainText"} inlineLabel={t("org.config.settings.allowWmbusKeysInPlainText", "Allow Organisation Admins to see wmbus AES-Key in plain Text.")} />
                        </SldsFormElementCompound>
                        <FormActions>
                            <SubmitButtonField />
                            <CancelButtonField />
                        </FormActions>
                    </Form>
                }}
            </Formik>
        </div>
    </Page>
}

//