import React, {useState} from "react"
import {useT} from "../../common/i18n";
import {useFilter} from "../../common/hooks/useFilter";
import {useQuery} from "@apollo/client";
import {QUERY_API_STATISTICS, QUERY_ORG_NAMES_BY_IDS} from "./queries";
import Highcharts from "highcharts";
import {Log} from "../../common/log";
import HighchartsReact from "highcharts-react-official";
import {ApiStatisticGQL, ApiStatisticResult, OrgByIDsGQL} from "./apiStatisticsPage";
import {Combobox, comboboxFilterAndLimit} from "@salesforce/design-system-react";
import FilterPanel from "../../common/ui/filterPanel";
import {Col, Row} from "antd";
import {Checkbox} from "../../common/slds/inputs/Checkbox";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {useOutletContext} from "react-router";

interface SldsComboboxOption {
    icon?: React.ReactNode;
    id: string;
    label: string;
    subTitle?: string;
    title?: string;
    type?: "separator";
    disabled?: boolean;
    tooltipContent?: string;
}

function findLabel(options: SldsComboboxOption[], label: string): number {
    return options.findIndex((option) => option.label === label)
}

function makeOrgGraphs(data: ApiStatisticResult[] | undefined, selectedUrl: SldsComboboxOption[] | undefined, orgs: Map<number, string> | undefined): Highcharts.SeriesOptions[] {
    if (!data || !orgs) {
        return []
    }
    const tempRes = [...orgs.entries()].map(([orgId, orgName]) => {
        const entry = {
            type: "column",
            name: orgName || `Org ${orgId}`, // TODO: Map to org name via GraphQL?
            // If no url is selected, map all
            data: data.filter((stat) => (stat.organisationId == orgId) && (selectedUrl && selectedUrl[0] ? stat.url === selectedUrl[0].label : true)).map((stat) => {
                //Log.Debug(stat)
                return [Date.parse(stat.createdAt), stat.count]
            }),
        }
        if (entry.data.length > 0) {
            return entry
        }
    })
    // Filter out entries with no data
    const res = tempRes.filter((entry) => entry !== undefined) as Array<{type: string, name: string, data: number[][]}>
    Log.Debug("API stat graph", res)
    return res
}

const initialFilter = [{"field": 'createdAt', "op": "gte", "value": new Date(Date.now()-1000*60*60*24)}, {"field": 'createdAt', "op": "lte", "value": new Date(Date.now())}] // 24 hours

export const OrgStatView = ({forOrg = 0}) => {
    const t = useT()
    const filters = useFilter(true, initialFilter);
    const sort = {field: "createdAt", direction: "ASC"} // static sort for Highcharts
    const [selectedUrl, setUrl] = useState<SldsComboboxOption[]>([])
    const [urlInput, setInput] = useState<string>("")
    const [withSuborgs, setSuborgs] = useState<boolean>(false)
    const hasSuborgs = useOutletContext()

    const perOrgData = useQuery<ApiStatisticGQL>(QUERY_API_STATISTICS, {
        variables: {
            filter: filters.getGraphqlFilterInput(),
            sort: sort,
            orgID: forOrg,
            separateOrgs: true,
            withSuborgs: withSuborgs,
        },
    });

    const loading = useGraphqlLoadingComponent(perOrgData)
    // Deep-copy perOrgData to interData, to allow adding up the counts for the same URL and timestamp
    const interData = {data: JSON.parse(JSON.stringify(perOrgData.data || {apiStatistic: []}))} as minimalGQLResult
    Log.Debug("interData", interData)

    const totalData = {
        data: ({
            apiStatistic: interData.data?.apiStatistic.reduce((acc, stat) => {
                // clear org id, matching GraphQL result for unseparated orgs
                stat.organisationId = 0
                const curIdx = acc.findIndex((entry) => (entry.url === stat.url) && (entry.createdAt === stat.createdAt))
                if (curIdx === -1) {
                    acc.push(stat)
                } else {
                    acc[curIdx].count += stat.count
                }
                return acc
            }, [] as ApiStatisticResult[]) || []
        }) || {apiStatistic: []}
    }
    Log.Debug("totalData", totalData)
    const resData = selectedUrl.length > 0 ? perOrgData : totalData

    const comboboxUrls = [...new Set(resData.data?.apiStatistic.map((stat: ApiStatisticResult) => stat.url) || null).values()].map((url, i) => {
        return {id: i.toString(), label: url}
    })
    const resOrgs = [...new Set(resData.data?.apiStatistic.map((stat: ApiStatisticResult) => stat.organisationId) || null).values()]

    const orgInfo = useQuery<OrgByIDsGQL>(QUERY_ORG_NAMES_BY_IDS, {
        variables: {
            ids: resOrgs.filter((orgId) => orgId > 0), // Exclude 0, -1 and -2
        },
    })
    // "Flatten" org info to id -> name map
    const orgNames = new Map(orgInfo.data?.getOrganisationsByIds.map((org) => [parseInt(org.id), org.name]) || [])
    // Add 0 -> "Total",  -1 -> "Public Access", -2 -> "Admin Token" to orgNames
    orgNames.set(0, "Total")
    orgNames.set(-1, "Public Access")
    orgNames.set(-2, "Admin Token")
    // Ensure all orgs in resOrgs are in orgNames, or set their names to ""
    //Log.Debug("Sanity test: Sane = ", orgNames.has(0), orgNames.has(-1), orgNames.has(-2))
    //Log.Debug("orgNames", orgNames)
    resOrgs.forEach((orgId) => {
        //Log.Debug("Checking orgId", orgId)
        //Log.Debug("type", typeof orgId)
        //Log.Debug("InMap", orgNames.has(orgId))
        if (!orgNames.has(orgId)) {
            orgNames.set(orgId, "")
        }
    })
    //Log.Debug("New orgNames", orgNames)

    const chartOptions: Highcharts.Options = {
        title: {
            text: t("config.settings.api-statistics.org-chart", "API access by organisation") || "API access by organisation"
        },
        xAxis: {
            gridLineWidth: 1,
            type: "datetime",
            title: {
                text: t("config.settings.api-statistics.time", "Time") || "Time"
            },
        },
        yAxis: {
            title: {
                text: t("config.settings.api-statistics.count", "Access count") || "Access count"
            },
        },
        series: makeOrgGraphs(resData.data?.apiStatistic, selectedUrl, orgNames) as Highcharts.SeriesOptionsType[],
        chart: {
            panKey: "ctrl",
            zooming: {
                type: "x",
            }
        },
    }

    return (
        <div className="slds-m-horizontal--x-small">
            <FilterPanel align={"center"} filters={filters} size={"full"} fieldName={"createdAt"}/>
            {hasSuborgs && (forOrg > 0) ? <Checkbox onClick={e => setSuborgs(e.target.checked)} checked={withSuborgs} label={t("config.settings.api-statistics.include-suborgs", "Include sub-organisations") || "Include sub-organisations"}/> : null}
            <Combobox
                id="combobox-id-1"
                events={{
                    onSelect: e => {
                        Log.Debug("Selected: ", e.target.title , e)
                        setUrl(e.target.title ? [comboboxUrls[findLabel(comboboxUrls, e.target.title)]] : [])
                    },
                    onChange: e => {
                        Log.Debug("Changed text: ", e)
                        setInput(e.target.value)
                    },
                    onRequestRemoveSelectedOption: () => {
                        setUrl([])
                    } // Not unused, needed by SLDS combobox
                }}
                labels={{
                    label: t("config.settings.api-statistics.select-url", "Select an endpoint") || "Select an endpoint",
                    placeholder: t("config.settings.api-statistics.select-url-long", "Select an endpoint to view statistics for") || "Select an endpoint to view statistics for"
                }}
                options={comboboxFilterAndLimit({
                    inputValue: urlInput,
                    options: comboboxUrls,
                    selection: selectedUrl,
                })}
                predefinedOptionsOnly={true}
                selection={selectedUrl}
                value={urlInput}
            />
            {loading || resData.data?.apiStatistic.length ? <HighchartsReact highcharts={Highcharts} options={chartOptions}/> : <Row align={"middle"} justify={"center"} className={"slds-p-vertical--xx-large"}><Col>{t("config.settings.api-statistics.no-results", "No statistics found")}</Col></Row> }
        </div>
    )
}

interface minimalGQLResult {
    data: {
        apiStatistic: ApiStatisticResult[]
    }
}
