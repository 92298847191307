import React from "react"
import Page from "../../common/ui/page";
import {Link, Outlet} from "react-router-dom";
import {useT} from "../../common/i18n";
import {Tab, TabNavigation} from "../../common/ui/tabNavigation";
import {useAuthContext} from "../../common/context/authContext";

export const ApiStatisticsPage = () => {
    const t = useT();
    const auth = useAuthContext();

    // Don't show tabs if user is not admin TODO: Better (looking) error message
    if (!auth.hasRole("admin")) {
        return (
            <Page
                trail={[<Link key={1} to={"."}>{t("config.settings.api-statistics.title", "API Statistics")}</Link>]}
                title={t("config.settings.api-statistics.title", "API Statistics")}
            >
                <div>Insufficient rights!</div>
            </Page>
        )
    }

    return (
        <Page
            trail={[<Link key={1} to={"."}>{t("config.settings.api-statistics.title", "API Statistics")}</Link>]}
            title={t("config.settings.api-statistics.title", "API Statistics")}
        >
            <TabNavigation tabs={[
                <Tab key={"endpoint"} to={"endpoint"}>{t("config.settings.api-statistics.endpoint", "Endpoint")}</Tab>,
                <Tab key={"organisation"} to={"organisation"}>{t("common.organisation", "Organisation")}</Tab>,
                ]}>
                <Outlet/>
            </TabNavigation>
        </Page>
    )
}

export interface ApiStatisticResult {
    createdAt: string
    count: number
    url: string
    organisationId: number
}

export interface ApiStatisticGQL {
    apiStatistic: ApiStatisticResult[]
}

export interface EndpointStatViewProps {
    forOrg?: number
}

export interface OrgByIDsGQL {
    getOrganisationsByIds: MinimalOrg[]
}

export interface MinimalOrg {
    id: string // Org ids are strings because
    name: string
}
