import gql from "graphql-tag";

export const QUERY_API_STATISTICS = gql`
    query($filter: [FilterInputType!], $sort: SortInputType, $separateOrgs: Boolean, $withSuborgs: Boolean, $orgID: ID, $url: String) {
        apiStatistic(filter: $filter, sort: $sort, separateOrgs: $separateOrgs, withSuborgs: $withSuborgs, orgId: $orgID, url: $url) {
            createdAt
            organisationId
            url
            count
        }
    }
`;

export const QUERY_ORG_NAMES_BY_IDS = gql`
    query($ids: [ID!]) {
        getOrganisationsByIds(IDs: $ids) {
            id
            name
        }
    }
`;
