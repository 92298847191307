import { useMatch, useNavigate } from "react-router-dom"
import Icon, {
    BlockOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    ClusterOutlined,
    DeploymentUnitOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
} from "@ant-design/icons"
import Url from "../../common/url"
import React, { useEffect, useState } from "react"
import { Affix, Badge, Button as AntButton, Dropdown, Menu, Tooltip } from "antd"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import _ from "lodash"
import usePrevious from "../../common/hooks/usePrevious"
import { useT } from "../../common/i18n"
import {
    GoogleHolidayVillageSVG,
    GoogleSelectAllSVG,
    GoogleSettingsRemoteSVG,
    GoogleWarehouseSVG
} from "../../common/svg/iconSVGs";
import { useAuthContext } from "../../common/context/authContext"

const menuOrgPrefix = "menu_settings_org_"
const menuKeyPrefix = "menu_key_"
const menuSettingsDeviceOrderPrefix = "menu_settings_devicesOrder"
const menuSettingsDeviceOrderByPrefix = "menu_settings_orderBy"

const settingsKey = "lobaro.settings.dtm"
const lobaroBlue = "#1b96ff"

const toDeviceTypeId = (deviceTypeId) => {
    if (!deviceTypeId) {
        return "all"
    }
    return deviceTypeId
}

function equality(prevMenuItemsState, menuItemsState) {
    let equal = true
    for (let i = 0; i < prevMenuItemsState.length; i++) {
        if (!menuItemsState.includes(prevMenuItemsState[i])) {
            equal = false
            break
        }
    }
    if (prevMenuItemsState.length !== menuItemsState.length) {
        equal = false
    }
    return equal
}

const menuItemStyle = {
    paddingLeft: "1.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.2rem",
    minHeight: "2.8rem",
    height: "auto",
    lineHeight: "1.2rem",
    fontSize: "0.8rem",
}

const menuCollapseItemStyle = {
    paddingTop: "0.2rem",
    whiteSpace: "normal",
    minHeight: "2.4rem",
    height: "auto",
    lineHeight: "1.4rem",
}

export const DeviceTypeMenu = ({ deviceTypes, collapsed, handleCollapse, baseUrl, currentScope, setCurrentScope, showDeviceTypeAll }) => {
    const t = useT()

    const auth = useAuthContext()
    const match = useMatch(baseUrl + "/:devTypeId")
    const devTypeId = match?.params?.devTypeId

    const navigate = useNavigate()

    const localSettings = JSON.parse(localStorage.getItem(settingsKey))

    const initialDevicesOrderBy = localSettings?.devicesOrderBy ? localSettings?.devicesOrderBy : menuSettingsDeviceOrderByPrefix + "_count"
    const [devicesOrderByState, setDevicesOrderByState] = useState(initialDevicesOrderBy)

    const initialDevicesOrder = localSettings?.devicesOrder ? localSettings?.devicesOrder : menuSettingsDeviceOrderPrefix + "_desc"
    const [devicesOrderState, setDevicesOrderState] = useState(initialDevicesOrder)
    const [firstRunDone, setFirstRunDone] = useState(false)

    const currentPathId = devTypeId ? devTypeId : "all"

    const defaultItems = showDeviceTypeAll
        ? [
              {
                  key: menuKeyPrefix + "all",
                  label: t("devices.page.scope.global", "All"),
                  icon: <BlockOutlined />,
                  linkTo: Url.join(baseUrl, "all"),
              },
          ]
        : []

    const menuItems =
        deviceTypes && deviceTypes.length > 0
            ? defaultItems.concat(
                  deviceTypes?.map((deviceType) => {
                      return {
                          key: menuKeyPrefix + deviceType.id,
                          deviceTypeId: deviceType.id,
                          label: deviceType.displayName,
                          icon: <Icon component={GoogleSettingsRemoteSVG} />,
                          linkTo: Url.join(baseUrl, deviceType.id),
                      }
                  })
              )
            : defaultItems

    const [menuItemsState, setMenuItemsState] = useState(menuItems)
    const prevMenuItemsState = usePrevious(menuItemsState)

    useEffect(() => {
        if (menuItems.length !== menuItemsState.length) {
            setMenuItemsState(menuItems)
        }
    })

    useEffect(() => {
        if (!firstRunDone) {
            // first run will trigger the first sorting

            handleMenuItemSort()
            setFirstRunDone(true)
        }
    })

    useEffect(() => {
        // reset into first run
        if (!prevMenuItemsState) {
            return
        }

        let equal = equality(prevMenuItemsState, menuItemsState)

        if (menuItemsState && menuItemsState.length > 0 && !equal) {
            setFirstRunDone(false)
        }
    }, [currentScope, menuItemsState])

    const saveSetting = (key, value) => {
        let settings = JSON.parse(localStorage.getItem(settingsKey))
        localStorage.setItem(
            settingsKey,
            JSON.stringify({
                ...settings,
                [key]: value,
            })
        )
    }

    const handleClickDeviceOrder = (orderKey) => {
        saveSetting("devicesOrder", orderKey)
        setDevicesOrderState(orderKey)
        handleMenuItemSort(null, orderKey)
    }

    const handleClickDeviceOrderBy = (orderByKey) => {
        saveSetting("devicesOrderBy", orderByKey)
        setDevicesOrderByState(orderByKey)

        // change the order of the menuItemsState based on the new order by type
        handleMenuItemSort(orderByKey, null)
    }

    function handleMenuItemSort(orderByKey, devicesOrderKey) {
        // input overrides the state
        let orderBy = orderByKey || devicesOrderByState
        let devicesOrder = devicesOrderKey || devicesOrderState

        setMenuItemsState((prev) => {
            if (orderBy === menuSettingsDeviceOrderByPrefix + "_count") {
                let sorted = _.sortBy(prev, ["count"])
                if (devicesOrder === menuSettingsDeviceOrderPrefix + "_desc") {
                    sorted = sorted.reverse()
                }
                return sorted
            }
            if (orderBy === menuSettingsDeviceOrderByPrefix + "_alpha") {
                let sorted = _.sortBy(prev, [(item) => item.label.toLowerCase()])
                if (devicesOrder === menuSettingsDeviceOrderPrefix + "_asc") {
                    sorted = sorted.reverse()
                }
                return sorted
            }
        })
    }

    const menuSelect = (selected) => {
        if (selected?.key.includes(menuSettingsDeviceOrderByPrefix)) {
            handleClickDeviceOrderBy(selected.key)
        }
        if (selected?.key.includes(menuSettingsDeviceOrderPrefix)) {
            handleClickDeviceOrder(selected.key)
        }
        if (selected?.key.includes(menuOrgPrefix)) {
            setCurrentScope(selected?.key.replace(menuOrgPrefix, ""))
        } else {
            let linkTo = selected?.item?.props?.linkTo
            navigate(linkTo)
        }
    }

    const getSubMenu = () => {
        return (
            <>
                {menuItemsState
                    ? menuItemsState.map((item) => {
                          if (item) {
                              return (
                                  <>
                                      <Menu.Item key={item.key} linkTo={item.linkTo} style={collapsed ? menuCollapseItemStyle : menuItemStyle}>
                                          <DeviceTypeBadge
                                              collapsed={collapsed}
                                              deviceTypeId={item?.deviceTypeId}
                                              scope={currentScope}
                                              orgId={auth.organisationId()}
                                              menuItemsState={menuItemsState}
                                              setMenuItemsState={setMenuItemsState}>
                                              {item.label}
                                          </DeviceTypeBadge>
                                      </Menu.Item>
                                      {menuItemsState[menuItemsState.length - 1] !== item && <Menu.Divider dashed={true} />}
                                  </>
                              )
                          }
                      })
                    : undefined}
            </>
        )
    }

    const getScopeIcon = (scope, style, fill) => {
        if (!auth.hasRole("admin", "org-admin")) {
            return null
        }
        if (scope === "organisation") {
            return <GoogleWarehouseSVG style={style} fill={fill} />
        } else if (scope === "organisationWithSub") {
            return <GoogleHolidayVillageSVG style={style} fill={fill} />
            // return <GoogleAccountTreeSVG style={style} fill={fill}/>
        } else if (scope === "global") {
            return <GoogleSelectAllSVG style={style} fill={fill} />
        }
    }

    const getSettingsMenu = () => {
        return (
            <>
                {currentScope && setCurrentScope && auth.hasRole("admin", "org-admin") && (
                    <Menu.ItemGroup title={t("device-types.settings.organisational-scope", "Organisational Scope")} icon={<DeploymentUnitOutlined />}>
                        <Menu.Item
                            key={menuOrgPrefix + "organisation"}
                            icon={
                                currentScope === "organisation" ? (
                                    <CheckOutlined style={{ color: "green" }} />
                                ) : (
                                    getScopeIcon("organisation", { marginRight: "0.5rem", marginBottom: "2px" })
                                )
                            }
                            onClick={menuSelect}>
                            {t("device-types.settings.scope.organisation", "Organisation")}
                        </Menu.Item>
                        <Menu.Item
                            key={menuOrgPrefix + "organisationWithSub"}
                            icon={
                                currentScope === "organisationWithSub" ? (
                                    <CheckOutlined style={{ color: "green" }} />
                                ) : (
                                    getScopeIcon("organisationWithSub", { marginRight: "0.5rem", marginBottom: "2px" })
                                )
                            }
                            onClick={menuSelect}>
                            {t("device-types.settings.scope.organisationWithSub", "Organisation & Suborgs")}
                        </Menu.Item>
                        {auth.hasRole("admin") && (
                            <Menu.Item
                                key={menuOrgPrefix + "global"}
                                icon={
                                    currentScope === "global" ? (
                                        <CheckOutlined style={{ color: "green" }} />
                                    ) : (
                                        getScopeIcon("global", { marginRight: "0.5rem", marginBottom: "2px" })
                                    )
                                }
                                onClick={menuSelect}>
                                {t("device-types.settings.scope.global", "All")}
                            </Menu.Item>
                        )}
                    </Menu.ItemGroup>
                )}
                <Menu.ItemGroup
                    title={t("device-types.settings.order-by.title", "Order By Type")}
                    icon={devicesOrderState === menuSettingsDeviceOrderPrefix + "_asc" ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                    onClick={menuSelect}>
                    <Menu.Item
                        key={menuSettingsDeviceOrderByPrefix + "_count"}
                        icon={devicesOrderByState === menuSettingsDeviceOrderByPrefix + "_count" && <CheckOutlined style={{ color: "green" }} />}
                        onClick={menuSelect}>
                        {t("device-types.settings.order-by.count", "Count")}
                    </Menu.Item>
                    <Menu.Item
                        key={menuSettingsDeviceOrderByPrefix + "_alpha"}
                        icon={devicesOrderByState === menuSettingsDeviceOrderByPrefix + "_alpha" && <CheckOutlined style={{ color: "green" }} />}
                        onClick={menuSelect}>
                        {t("device-types.settings.order-by.alphabetical", "Alphabetical")}
                    </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup
                    title={t("device-types.settings.order.title", "Order")}
                    icon={devicesOrderState === menuSettingsDeviceOrderPrefix + "_asc" ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                    onClick={menuSelect}>
                    <Menu.Item
                        key={menuSettingsDeviceOrderPrefix + "_desc"}
                        icon={devicesOrderState === menuSettingsDeviceOrderPrefix + "_desc" && <CheckOutlined style={{ color: "green" }} />}
                        onClick={menuSelect}>
                        {t("device-types.settings.order.descending", "Descending")}
                    </Menu.Item>
                    <Menu.Item
                        key={menuSettingsDeviceOrderPrefix + "_asc"}
                        icon={devicesOrderState === menuSettingsDeviceOrderPrefix + "_asc" && <CheckOutlined style={{ color: "green" }} />}
                        onClick={menuSelect}>
                        {t("device-types.settings.order.ascending", "Ascending")}
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        )
    }

    return (
        <>
            <Menu
                subMenuCloseDelay={0.1}
                subMenuOpenDelay={0.1}
                selectedKeys={[menuKeyPrefix + currentPathId]}
                defaultOpenKeys={!collapsed ? ["MenuDeviceTypes"] : []}
                mode="vertical"
                onSelect={menuSelect}>
                {collapsed && (
                    <>
                        <Affix style={{ position: "absolute", top: 0, left: 38, zIndex: "auto" }}>
                            <Tooltip title={collapsed ? t("menu.expand", "Expand Menu") : t("menu.collapse", "Collapse Menu")}>
                                <div className="space-align-container">
                                    <AntButton
                                        style={{ height: "40px" }}
                                        size="large"
                                        type="text"
                                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                        onClick={() => handleCollapse(!collapsed)}
                                    />
                                </div>
                            </Tooltip>
                        </Affix>
                        <Menu.SubMenu className="lobaro-side-menu-collapsed" title={t("device-types.settings-title", "Settings")} icon={<SettingOutlined />}>
                            {getSettingsMenu()}
                        </Menu.SubMenu>
                        <Menu.Divider style={{ borderWidth: "1px", borderColor: "#c9c9c9" }} />
                        <Menu.SubMenu
                            key={"MenuDeviceTypes"}
                            title={
                                <>
                                    {t("device-types.title", "Device Types")}
                                    <GoogleHolidayVillageSVG style={{ marginLeft: "0.5rem" }} fill="rgba(0, 0, 0, 0.45)" />
                                </>
                            }
                            icon={<ClusterOutlined />}>
                            <Menu.ItemGroup
                                title={
                                    <>
                                        {t("device-types.title", "Device Types")}
                                        {getScopeIcon(currentScope, { marginLeft: "0.5rem" }, "rgba(0, 0, 0, 0.45)")}
                                    </>
                                }>
                                {getSubMenu()}
                            </Menu.ItemGroup>
                        </Menu.SubMenu>
                    </>
                )}
                {!collapsed && (
                    <>
                        <Menu.Item className="lobaro-side-menu-expanded">
                            <Dropdown dropdownRender={() => <Menu title={t("device-types.settings-title", "Settings")}>{getSettingsMenu()}</Menu>}>
                                <AntButton size="large" type="text" icon={<SettingOutlined />} />
                            </Dropdown>
                            <Tooltip title={collapsed ? t("menu.expand", "Expand Menu") : t("menu.collapse", "Collapse Menu")}>
                                <div style={{ float: "inline-end" }}>
                                    <AntButton
                                        size="large"
                                        type="text"
                                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                        onClick={() => handleCollapse(!collapsed)}
                                    />
                                </div>
                            </Tooltip>
                        </Menu.Item>
                        <Menu.Divider style={{ borderWidth: "1px", borderColor: "#c9c9c9" }} />
                        <Menu.ItemGroup
                            key={"MenuDeviceTypes"}
                            title={
                                <>
                                    {t("device-types.title", "Device Types")} {getScopeIcon(currentScope, { marginLeft: "0.5rem" }, "rgba(0, 0, 0, 0.45)")}
                                </>
                            }
                            // icon={<ClusterOutlined />}
                        >
                            {getSubMenu()}
                        </Menu.ItemGroup>
                    </>
                )}
            </Menu>
        </>
    )
}

function DeviceTypeBadge({ deviceTypeId, scope, orgId, children, menuItemsState, setMenuItemsState }) {
    const QUERY_DEVICES_COUNT = gql`
        query ($devTypeId: ID, $orgId: ID!, $scope: String!) {
            devicesCount(devTypeId: $devTypeId, orgId: $orgId, scope: $scope, onlyWithExistingDevices: true) {
                id
                deviceCount {
                    orgId
                    deviceTypeId
                    count
                }
            }
        }
    `

    const devicesCountResult = useQuery(QUERY_DEVICES_COUNT, {
        variables: {
            devTypeId: deviceTypeId,
            scope: scope ? scope : "organisation",
            orgId: orgId,
        },
        fetchPolicy: "cache-first",
        pollInterval: _.random(60, 120) * 1000, // 1 - 2 minutes
    })

    const distinctCount = devicesCountResult?.data?.devicesCount?.deviceCount
    const count =
        distinctCount !== null && distinctCount !== undefined && distinctCount.length > 0
            ? distinctCount.reduce((accu, info) => {
                  return accu + info?.count
              }, 0)
            : 0
    let offset = [0, -5]

    useEffect(() => {
        if (menuItemsState && setMenuItemsState && count !== null && count !== undefined) {
            let lastCount = menuItemsState.find((item) => item.key === menuKeyPrefix + toDeviceTypeId(deviceTypeId)).count

            if (lastCount !== count) {
                setMenuItemsState((prev) => {
                    return prev.map((item) => {
                        if (item.key === menuKeyPrefix + toDeviceTypeId(deviceTypeId)) {
                            return { ...item, count: count }
                        }
                        return item
                    })
                })
            }
        }
    })

    return (
        <>
            {children}
            <br />
            <Badge
                count={devicesCountResult?.loading ? <ClockCircleOutlined style={{ color: lobaroBlue }} /> : count}
                overflowCount={99999}
                size={"small"}
                color={lobaroBlue}
                showZero={true}
                offset={offset}
            />
        </>
    )
}
