import React, {useContext} from "react";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import Json from "../../../common/ui/json";
import DataTableColumn from "../../../common/ui/data-table/column";
import moment from "moment";
import {ButtonGroup, Checkbox} from "@salesforce/design-system-react";
import Button from "../../../common/slds/buttons/button";
import {Log} from "../../../common/log";
import {useT} from "../../../common/i18n";
import {useAuthContext} from "../../../common/context/authContext";
import {SldsButton} from "../../../common/ui/form/formElements";
import Roles from "../../../model/roles";
import {useNotificationContext} from "../../../notifications/notificationContext";
import {Accordion, AccordionPanel} from "../../../common/ui/accordion";
import * as PropTypes from "prop-types";
import DownlinkFormik from "./downlinkFormik";
import {useParams} from "react-router";
import {FeatureContext} from "../../../common/context/featureContext";
import {FeatureNotEnabled} from "../../../common/featureNotEnabled";


const MUTATION_DELETE_UNCOMPLETED_DOWNLINK = gql`
    mutation ($id: ID!){
        deleteUncompletedDownlink(id: $id) {
            id
            createdAt
            type
            sent
            completed
            confirmable
            confirmed
            data
            device{
                id
            }
            error
        }
    }
`;
const MUTATION_CREATE_DOWNLINK = gql`
    mutation ($deviceId: ID!, $type: String!, $data: String!, $confirmable: Boolean!){
        createDownlink(deviceId: $deviceId, type: $type, data: $data, confirmable: $confirmable) {
            id
            createdAt
            type
            sent
            completed
            confirmable
            confirmed
            data
            device{
                id
            }
            error
        }
    }
`;

const QUERY_DOWNLINKS = gql`
    query deviceDownlinks($devId: ID!){
        downlinkList(devId: $devId, showCompleted: true) {
            id
            createdAt
            type
            sent
            completed
            confirmable
            confirmed
            data
            device{
                id

            }
            error
        }
    }
`;


function Field() {
    return null;
}

Field.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    component: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string
};
export default function DeviceDownlinkData() {
    const license = useContext(FeatureContext)
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled/>
    }
    const t = useT();
    const authCtx = useAuthContext();
    const notify = useNotificationContext();
    const devId = useParams().deviceId;

    const downlinksResult = useQuery(QUERY_DOWNLINKS, {
        variables: {
            devId: devId,
        },
        fetchPolicy: "network-only",
    });

    const [createDownlink] = useMutation(MUTATION_CREATE_DOWNLINK);
    const [deleteDownlink] = useMutation(MUTATION_DELETE_UNCOMPLETED_DOWNLINK);

    const downlinks = downlinksResult.data?.downlinkList || [];

    const completedDl = downlinks.filter(it => it.completed);
    const queuedDl = downlinks.filter(it => !it.completed);


    return <div className="slds-grid slds-grid_vertical slds-p-horizontal--small">
        <div className="slds-col_bump-left slds-float_right">
            <ButtonGroup className="slds-p-bottom--x-small slds-p-horizontal--small">
                <Button iconCategory={"utility"} iconName={"refresh"} iconVariant={"border"}
                        onClick={() => downlinksResult.refetch()}
                />
            </ButtonGroup>
        </div>
        {authCtx.hasRole(Roles.ADMIN, Roles.ORG_ADMIN) ?
        <Accordion>
            <AccordionPanel summary={t("device.downlinks.downlink-accordion","Schedule Downlink")} id="downlink-form-panel" expanded={true}>
                <DownlinkFormik onSubmitFunction={(values) => {
                    createDownlink(  {variables: {
                            deviceId: devId,
                            type: values.type,
                            data: values.data,
                            confirmable: !!values.confirmable,
                        },
                        refetchQueries: [{
                            query: QUERY_DOWNLINKS,
                            variables: {
                                devId: devId,
                            }
                        }]
                    }).catch((err) => {
                        notify.error(t("device.downlinks.failed","Failed to reschedule downlink"), err);
                    }).finally(() => {
                        notify.success(t("device.downlinks.success","Successfully rescheduled downlink"));
                    })}}/>
                        </AccordionPanel>
        </Accordion> : null}


        <div className="slds-text-heading--medium">{t("device.downlinks.queued-downlinks", "Queued downlinks")}</div>
        <GenericDataTable id="downlinks" fixedLayout={true} items={queuedDl} renderDetails={(item) => {
            Log.Debug("props", item);
            return <Json jsonString={item.data}/>;
        }}>
            <DataTableColumn label={t("device.downlinks.created", "Created")} render={(props) => moment(props.item.createdAt).format("DD.MM.YYYY HH:mm:ss")}/>
            <DataTableColumn label={t("device.downlinks.type", "Type")} property={"type"}/>
            <DataTableColumn label={t("device.downlinks.confirmable", "Confirmable")} id={"cc"} render={(props) => <Checkbox checked={props.item.confirmable} readOnly/>}/>
            <DataTableColumn label={t("device.downlinks.confirmed", "Confirmed")} render={(props) => <Checkbox checked={props.item.confirmed} readOnly/>}/>
            <DataTableColumn label={t("device.downlinks.completed", "Completed")} render={(props) => <Checkbox checked={props.item.completed} readOnly/>}/>
            <DataTableColumn label={t("device.downlinks.sent-at", "Sent At")} render={(props) => {
                if (!props.item.sent) {
                    return  t("device.downlinks.not-yet", "not yet");
                }
                return moment(props.item.sent).format("DD.MM.YYYY HH:mm:ss");
            }}/>
            <DataTableColumn label={t("device.downlinks.error", "Error")} property={"error"}/>
            {authCtx.hasRole(Roles.ADMIN, Roles.ORG_ADMIN) ?
                <DataTableColumn label={t("device.downlinks.delete", "Delete")} render={(props) => {
                    return <SldsButton onClick={() => {
                        deleteDownlink(  {variables: {
                                id: props.item.id,
                              },
                            refetchQueries: [{
                                query: QUERY_DOWNLINKS,
                                variables: {
                                    devId: devId,
                                }
                            }]}).catch((err) => {
                            notify.error(t("device.downlinks.delete-failed","Failed to delete downlink"), err);
                        }).finally(() => {
                            notify.success(t("device.downlinks.delete-success","Successfully deleted downlink"));
                        });
                    }}>{t("device.downlinks.delete", "Delete")}</SldsButton>}
                }/>
                : null
            }
        </GenericDataTable>
        <div className="slds-text-heading--medium">{t("device.downlinks.completed-downlinks", "Completed downlinks")}</div>
        <GenericDataTable id="downlinks-table" fixedLayout={true} items={completedDl} renderDetails={(item) => {
            return <Json jsonString={item.data}/>;
        }}>
            <DataTableColumn label={t("device.downlinks.created", "Created")} render={(props) => moment(props.item.createdAt).format("DD.MM.YYYY HH:mm:ss")}/>
            <DataTableColumn label={t("device.downlinks.type", "Type")} property={"type"}/>
            <DataTableColumn label={t("device.downlinks.confirmable", "Confirmable")} id={"cc"} render={(props) => <Checkbox checked={props.item.confirmable} readOnly/>}/>
            <DataTableColumn label={t("device.downlinks.confirmed", "Confirmed")} render={(props) => <Checkbox checked={props.item.confirmed} readOnly/>}/>
            <DataTableColumn label={t("device.downlinks.completed", "Completed")} render={(props) => <Checkbox checked={props.item.completed} readOnly/>}/>
            <DataTableColumn label={t("device.downlinks.sent-at", "Sent At")} render={(props) => {
                if (!props.item.sent) {
                    return t("device.downlinks.not-yet", "not yet");
                }
                return moment(props.item.sent).format("DD.MM.YYYY HH:mm:ss");
            }}/>
            <DataTableColumn label={t("device.downlinks.error", "Error")} property={"error"}/>
            {authCtx.hasRole(Roles.ADMIN, Roles.ORG_ADMIN) ?
                <DataTableColumn label={t("device.downlinks.reschedule", "Re-Schedule")} render={(props) => {
                    return <SldsButton onClick={() => {
                        createDownlink(  {variables: {
                                deviceId: devId,
                                type: props.item.type,
                                data: props.item.data,
                                confirmable: props.item.confirmable,
                            },
                            refetchQueries: [{
                                query: QUERY_DOWNLINKS,
                                variables: {
                                    devId: devId,
                                }
                            }]
                        }).catch((err) => {
                            notify.error(t("device.downlinks.failed","Failed to reschedule downlink"), err);
                        }).finally(() => {
                            notify.success(t("device.downlinks.success","Successfully rescheduled downlink"));
                        });
                    }}>{t("device.downlinks.reschedule", "Re-Schedule")}</SldsButton>}
                }/>
                : null
            }
        </GenericDataTable>
    </div>
}
