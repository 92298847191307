import { useQuery } from "@apollo/client";
import {Log} from "../log";
import {download} from "../download";
import {compileHandlebars} from "../cachedHandlebarsCompiler";
import {useNotificationContext} from "../../notifications/notificationContext";
import {useT} from "../i18n";
import Papa from "papaparse";
import gql from "graphql-tag";
import { useState } from "react";

const QUERY_DEVICES = gql`
    query ($orgId: ID, $devTypeId: ID, $page: PaginationInputType, $sort: SortInputType, $scope: String!) {
        devices(orgId: $orgId, sort: $sort, page: $page, devTypeId: $devTypeId, scope: $scope) {
            devices {
                createdAt
                id
                addr
                name
                description
                configRaw
                propertiesRaw
                lastReceived
                serial
                tags
                initialConfigRaw
                firmwareVersion
                organisation {
                    id
                    name
                }
                deviceType {
                    id
                    displayName
                }
                app {
                    id
                    appId
                    name
                }
            }
        }
    }
`;

export const toCsvLine = (strings) => strings
        .map(s => "\"" + s.toString().replace("\"", "\"\"") + "\"")
        .join(";")
    + "\n";


const csvPageSize = 10000

export const useCsvExport = ( query, exportOptions ) => {
    const t = useT()
    const notify = useNotificationContext()
    const devicesQuery = useQuery(query, {
        skip: true,
        fetchPolicy: "network-only",
        variables: exportOptions.variables,
    })

    const [processing, setProcessing] = useState(false)

    const defaultDataExtractor = (d, multiSelection, exportAll) => {
        let devicesResult = d?.data?.devices?.devices

        // if exportAll is true overwrite the multiselection.
        if (!exportAll) {
            if (multiSelection?.selections?.length > 0) {
                let selectedIds = multiSelection.selections.map((s) => s.id)

                devicesResult = devicesResult.filter((d) => {
                    return selectedIds.includes(d.id)
                })
            } else {
                return []
            }
        }

        return devicesResult.map((d) => {
            let properties
            try {
                properties = JSON.parse(d.propertiesRaw) || {}
            } catch (err) {
                Log.Error("Failed to parse device propertiesRaw", err)
            }
            return {
                ...d,
                properties: properties,
            }
        })
    }

    return {
        isProcessing: processing,
        export: (tableConfig, multiSelection, exportAll) => {
            Log.Debug("CSV Export with Table config", tableConfig, "multiSelection", multiSelection, "exportAll", exportAll)
            let csvData = ""
            setProcessing(true)
            const exportedCols = tableConfig.cols.filter((col) => col.csvFormat?.length > 0)
            csvData += toCsvLine(exportedCols.map((col) => col.heading || ""))

            Log.Debug("Result", devicesQuery)
            let finished = false

            async function doit() {
                for (let i = 0; !finished; i++) {
                    let refetchResult = await devicesQuery.refetch({ page: { offset: i * csvPageSize, limit: csvPageSize } })
                    Log.Debug("useCsvResult.RefetchResult", refetchResult)
                    let y = 0
                    if (refetchResult.errors) {
                        notify.error(refetchResult.errors)
                        return
                    }
                    if (exportOptions?.dataExtractor) {
                        exportOptions.dataExtractor(refetchResult).forEach(item => {
                            //Log.Debug("item", item);
                            csvData += toCsvLine(exportedCols.map(col => compileHandlebars(col.csvFormat || col.cell?.format, item)));
                            y++
                        });
                    } else {
                        defaultDataExtractor(refetchResult, multiSelection, exportAll).forEach((item) => {
                            //Log.Debug("item", item);
                            csvData += toCsvLine(exportedCols.map((col) => compileHandlebars(col.csvFormat || col.cell?.format, item)))
                            y++
                        })
                    }
                    // if not finished y will be >= csvPageSize
                    finished = y < csvPageSize || y === 0
                }
                download(csvData, "export.csv", "text/csv")
                notify.success(t("csv.success", "downloaded data"))
                setProcessing(false)
            }
            doit().catch((err) => {
                notify.error(t("csv.failed", "failed to download data"), err)
                setProcessing(false)
            })
        },
    }
}

export const useDevicesHardwareCsvExport = (devicesQueryVariables) => {
    const t = useT()
    const notify = useNotificationContext();
    const queryResult = useQuery(QUERY_DEVICES, {
        skip: true,
        fetchPolicy: "network-only",
        variables: devicesQueryVariables
    });
    const [processing, setProcessing] = useState(false);

    return {
        isProcessing: processing,
        export: (multiSelection, exportAll) => {
            Log.Debug("Hardware CSV export ");


            Log.Debug("Result", queryResult);
            let finished = false

            async function doit() {
                let configMeta = {}
                let headers = ["serial", "created_at", "uid", "address", "firmware"]
                let csvDevices = []

                // all elements need to be fetched first, because the header fields can change with every element
                let aggregatedResults = []
                setProcessing(true)

                for (let i = 0; !finished; i++) {
                    let refetchResult = await queryResult.refetch({ page: { offset: i * csvPageSize, limit: csvPageSize } })
                    let y = 0
                    if (refetchResult.errors) {
                        notify.error(refetchResult.errors)
                        return
                    }

                    let devicesResult = refetchResult?.data?.devices?.devices;
                    aggregatedResults.push(...devicesResult)

                    y = devicesResult?.length || 0
                    finished = y < csvPageSize || y === 0
                }

                Log.Debug("useHardwareCsvExport.export", "aggregatedResults", aggregatedResults,  "multiSelection", multiSelection, "exportAll", exportAll);

                if (!exportAll) {
                    if (multiSelection?.selections?.length > 0) {
                        let selectedIds = multiSelection.selections.map((s) => s.id);

                        aggregatedResults = aggregatedResults.filter((d) => {
                            return selectedIds.includes(d.id);
                        });

                        Log.Debug("useHardwareCsvExport.export.multiSelection", "aggregatedResults", aggregatedResults,  "multiSelection", multiSelection, "exportAll", exportAll);
                    }
                }

                // determine headers
                aggregatedResults?.forEach((device) => {
                        let initialConfig = JSON.parse(device?.initialConfigRaw) || {}
                        for (const [key] of Object.entries( initialConfig )) {
                            configMeta[key] = true
                        }
                    })

                    for (const [key] of Object.entries(configMeta)) {
                        headers.push("cfg:" + key)
                    }


                aggregatedResults?.forEach((device) => {
                        let exportDev = {
                            serial: device.serial,
                            created_at: device.createdAt,
                            uid: "",
                            address: device.addr,
                            firmware: device.firmwareVersion || "",
                        }
                        let initialConfig = JSON.parse(device.initialConfigRaw) || {}

                        for (const [key, value] of Object.entries(initialConfig)) {
                            let csvKey = "cfg:" + key
                            exportDev[csvKey] = value
                        }
                        csvDevices.push(exportDev)
                    })


                let csvContent = Papa.unparse(csvDevices, {
                    quotes: false, //or array of booleans
                    quoteChar: '"',
                    escapeChar: '"',
                    delimiter: ";",
                    header: true,
                    newline: "\n",
                    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                    columns: headers, //or array of strings
                })

                download(csvContent, "devices.csv", "text/csv");
                notify.success(t("csv.success","downloaded data"))
                setProcessing(false)
            }
            doit().catch(err => {
                notify.error(t("csv.failed","failed to download data"), err)
                setProcessing(false)
            })
        }
    };
};
