import React, {Fragment} from "react";
import {Field, FieldArray} from "formik";
import {Form} from "../form/formik";
import PropTypes from "prop-types"
import {
    SldsButton,
    SldsButtonGroup,
    SldsCheckboxField,
    SldsFormElement,
    SldsFormElementCompound,
    SldsFormElementRow,
    SldsInputField,
    SldsSelectField,
    SldsTextarea,
    SldsTextareaField
} from "../form/formElements";
import {Accordion, AccordionPanel} from "../accordion";
import {useT} from "../../i18n";


export function GenericDataTableConfigForm(props) {
    const t = useT();
    const {formik, id} = props;

    const cols = formik?.values?.cols || [];
    const readOnly = formik?.status?.readOnly || false;
    return <Form id={id}>
        <FieldArray name={"cols"} render={(arrayHelpers) => {
            return <Fragment>
                <SldsCheckboxField name={`hasFilter`} inlineLabel={t("data-table-config-form.show-filter", "Show Filter")}/>
                <SldsInputField label={t("data-table-config-form.default-filter", "Default Filter")} name={`defaultFilterString`}/>
                <Accordion>
                    {cols.map((col, idx) => {
                        const cell = col.cell || {};
                        const colTitle = t("data-table-config-form.column-title", "Column {{idx}}: {{heading}}", {idx: idx + 1, heading: col.heading || ""});
                        return <AccordionPanel
                            key={idx}
                            id={idx}
                            summary={colTitle}
                            panelContentActions={!readOnly && <React.Fragment>
                                <SldsButtonGroup>
                                    <SldsButton iconCategory={"utility"} iconName={"arrowup"} iconVariant={"border"} variant={"icon"} disabled={idx === 0} onClick={() => arrayHelpers.move(idx, idx - 1)}/>
                                    <SldsButton iconCategory={"utility"} iconName={"arrowdown"} iconVariant={"border"} variant={"icon"} disabled={idx === formik.values.cols.length - 1} onClick={() => arrayHelpers.move(idx, idx + 1)}/>
                                    <SldsButton className={"slds-button_icon-error"} iconCategory={"utility"} iconName={"delete"} iconVariant={"border-filled"} variant={"icon"} onClick={() => arrayHelpers.remove(idx)}/>
                                </SldsButtonGroup>
                                <SldsButton iconCategory={"utility"} iconName={"add"} iconPosition={"left"} onClick={() => arrayHelpers.insert(idx, {})}>{t("data-table-config-form.add-column", "Add Column")}</SldsButton>
                            </React.Fragment>
                            }
                        >
                            <SldsFormElementCompound key={idx} label={"Column"}>
                                <SldsInputField label={t("data-table-config-form.heading", "Heading")} name={`cols.${idx}.heading`}>{col.heading}</SldsInputField>
                                <SldsInputField label={t("data-table-config-form.width", "Width")} name={`cols.${idx}.width`} placeholder={"e.g. 2rem"}>{col.width}</SldsInputField>
                                <SldsInputField label={t("data-table-config-form.sort-property", "Sort Property")} name={`cols.${idx}.sortProperty`} placeholder={"e.g. id"}>{col.sortProperty}</SldsInputField>
                                <SldsFormElementCompound label={t("data-table-config-form.filter-label", "Filter")}>
                                    <SldsFormElementRow>
                                        <SldsCheckboxField className="slds-size_1-of-12 slds-col slds-grow" label={t("data-table-config-form.filter-column", "Filter Column")} name={`cols.${idx}.filter.hasFilter`}/>
                                        <SldsTextareaField className="slds-size_6-of-12 slds-col" label={t("data-table-config-form.filter-property", "Filter-Property")} rows={1} name={`cols.${idx}.filter.property`} placeholder={t("data-table-config-form.filter-property-example", "e.g. {{name}}")}>{cell.format}</SldsTextareaField>
                                        <SldsSelectField  className="slds-size_5-of-12 slds-col" label={t("data-table-config-form.filter-type", "Type")} name={`cols.${idx}.filter.type`}
                                            options={ [
                                                {value: "string",  label: t("data-table-config-form.filter-type-string", "Text")},
                                                {value: "binary", label: t("data-table-config-form.filter-type-binary", "Binary")},
                                                {value: "list",  label: t("data-table-config-form.filter-type-list", "List")},
                                                {value: "boolean", label: t("data-table-config-form.filter-type-boolean", "True/False")},
                                            ] }/>
                                    </SldsFormElementRow>
                                </SldsFormElementCompound>
                                <SldsFormElementCompound label={t("data-table-config-form.visibility-label", "Visibility")}>
                                    <SldsFormElementRow>
                                        <SldsCheckboxField className="slds-size_1-of-12 slds-col slds-grow" label={t("data-table-config-form.columns-default-visibility", "Column Default Visibility")} name={`cols.${idx}.defaultVisible`} />
                                    </SldsFormElementRow>
                                </SldsFormElementCompound>
                                <SldsFormElementCompound label={"CSV Export"}>
                                    <SldsFormElementRow>
                                        <SldsTextareaField className="slds-size_11-of-12 slds-col" label={t("data-table-config-form.csv-format", "CSV Format")} rows={2} name={`cols.${idx}.csvFormat`} placeholder={t("data-table-config-form.csv-format-placeholder", "e.g. {{data.temperature}}", {interpolation: {prefix: "-no-interpolation-"}})}>{cell.format}</SldsTextareaField>
                                    </SldsFormElementRow>
                                </SldsFormElementCompound>
                                <SldsFormElementCompound label={t("data-table-config-form.cell", "Cell")}>
                                    <SldsFormElementRow>
                                        <SldsTextareaField className="slds-size_11-of-12 slds-col" label={t("data-table-config-form.format", "Format")} rows={2} name={`cols.${idx}.cell.format`} placeholder={`e.g. {{name}}`}>{cell.format}</SldsTextareaField>
                                        <SldsCheckboxField className="slds-size_1-of-12 slds-col slds-grow" label={"HTML"} name={`cols.${idx}.cell.isHtml`}/>
                                    </SldsFormElementRow>
                                    <SldsInputField label={t("data-table-config-form.href", "Href (optional link)")} name={`cols.${idx}.cell.href`} placeholder={`e.g. /link/to/page`}>{cell.href}</SldsInputField>
                                </SldsFormElementCompound>
                                <hr className="slds-m-vertical--small"/>
                            </SldsFormElementCompound></AccordionPanel>
                    })}
                </Accordion>
                {!readOnly && <SldsFormElement className="slds-m-top--x-small">
                    <SldsFormElementRow>
                        <SldsButton iconCategory={"utility"} iconName={"add"} iconPosition={"left"} onClick={() => arrayHelpers.push({})}>{t("data-table-config-form.add-column", "Add Column")}</SldsButton>
                        <SldsButton variant={"destructive"} iconCategory={"utility"} iconName={"undo"} iconPosition={"left"} onClick={() => {formik.setValues({})}}>{t("data-table-config-form.restore-default", "Clear")}</SldsButton>
                    </SldsFormElementRow>
                </SldsFormElement>}
                <Field component={SldsTextarea} name="previewTelegram" placeholder={t("data-table-config-form.telegram-for-preview-placeholder", "Insert \"Data (JSON)\" for Preview")} id="description"
                       label={t("data-table-config-form.telegram-for-preview", "Telegram for Preview")} rows={4} readOnly={readOnly}/>
            </Fragment>
        }}>

        </FieldArray>
    </Form>
}

GenericDataTableConfigForm.propTypes = {
    id: PropTypes.string,
    formik: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
};