import React, {useContext, useState} from "react";
import Page from "../../common/ui/page";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useAuthContext } from "../../common/context/authContext";
import { Link, Navigate, Outlet, useMatch } from "react-router-dom";
import Url from "../../common/url";
import PropTypes from "prop-types";
import { DeviceTypesNav } from "./deviceTypesNav";
import { useT } from "../../common/i18n";
import { DeviceTypeMenu } from "../devices/deviceTypeMenu";
import { Layout } from "antd";
import {FeatureContext} from "../../common/context/featureContext";

const { Sider } = Layout;

const settingsKey = "lobaro.settings.dtm";

export const QUERY_DEVICE_TYPES = gql`
    query ($orgId: ID, $onlyWithExistingDevices: Boolean, $withSubOrgs: Boolean) {
        deviceTypes(orgId: $orgId, onlyWithExistingDevices: $onlyWithExistingDevices, withSubOrgs: $withSubOrgs) {
            id
            displayName
            dataTableConfigRaw
            deviceTableConfigRaw
        }
    }
`;

DeviceTypesNav.propTypes = {
  deviceTypes: PropTypes.array
};

export const QUERY_DEVICE_TYPE = gql`
    query deviceType($devTypeId: ID!) {
        deviceType(id: $devTypeId) {
            id
            displayName
            deviceTableConfigRaw
        }
    }
`;

const DeviceDataPage = () => {
  const t = useT();
  const auth = useAuthContext();
  const match = useMatch("/organisation/deviceData/:devTypeId");
  const devTypeId = match?.params?.devTypeId;

  const localSettings = JSON.parse(localStorage.getItem(settingsKey));
  const initialCollapsed = localSettings?.collapsed !== null && localSettings?.collapsed !== undefined ? localSettings?.collapsed : false;
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const noSelectMatch = useMatch("/organisation/deviceData");
  const license = useContext(FeatureContext);

  const gqlResult = useQuery(QUERY_DEVICE_TYPES, {
    variables: {
      orgId: auth.organisationId(),
      onlyWithExistingDevices: true,
      scope: "organisation"
    }
  });
  const devTypeResult = useQuery(QUERY_DEVICE_TYPE, {
    skip: !devTypeId,
    variables: {
      devTypeId: devTypeId
    }
  });

  const saveSetting = (key, value) => {
    let settings = JSON.parse(localStorage.getItem(settingsKey));
    localStorage.setItem(
      settingsKey,
      JSON.stringify({
        ...settings,
        [key]: value
      })
    );
  };

  const handleCollapse = (collapsed) => {
    saveSetting("collapsed", collapsed);
    setCollapsed(collapsed);
  };

  const devType = devTypeResult.data?.deviceType;
  const deviceTypes = gqlResult?.data?.deviceTypes;

  if (deviceTypes?.length >= 1 && noSelectMatch) {
    return <Navigate to={Url.join("/organisation/deviceData", deviceTypes[0].id)} />;
  }

  return (
    <Layout
      style={{
        minHeight: "100vh"
      }}>
        {license.validateFeatures("lobaro-device-gateway") ?
            <Sider width={"15rem"} theme={"light"} collapsed={collapsed}>
                <DeviceTypeMenu deviceTypes={deviceTypes} baseUrl="/organisation/deviceData"
                            collapsed={collapsed}
                            handleCollapse={handleCollapse}
                            showDeviceTypeAll={false}/>
            </Sider> :
            <Sider width={"15rem"} theme={"light"} collapsed={collapsed}>
                <DeviceTypeMenu deviceTypes={[]} baseUrl="/organisation/deviceData" collapsed={collapsed} handleCollapse={handleCollapse} showDeviceTypeAll={false}/>
            </Sider>
        }
      <Layout>
        <Page
          title={devType?.displayName || "Device Data"}
          trail={[
            <Link to={"."} key={1}>
              {t("device-data.titel", "Device Data")}
            </Link>
          ]}>
          <Outlet />
        </Page>
      </Layout>
    </Layout>
  );
};

export default DeviceDataPage;
